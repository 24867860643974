import React, { useState, useRef } from 'react';
import {
    Menu,
    MenuItem,
    Button,
    Tabs,
    Tab,
    Divider,
    Slider,
    TextField,
    Box,
} from '@mui/material';
import { CloudUpload as CloudUploadIcon, Delete as DeleteIcon } from '@mui/icons-material';
import {
    ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';
import BackgroundIcon from "../../../assets/img/SignInTemplateAssets/backgroundIcon.svg"
import { imageHandlerUtil } from 'utils/imageHandlerUtil';

const ImageColorDropdown = ({ setTemplateObj, templateObj }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [image, setImage] = useState(null);
    const [imageFileName, setImageFileName] = useState('');
    const [color, setColor] = useState('#000000');

    const fileInputRef = useRef(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const handleReplaceImage = () => {
        // Trigger file input click
        // if (fileInputRef.current) {
        //   fileInputRef.current.click(); // Open file selection dialog
        // }
        document.getElementById('image-replace').click();
    };

    const handleImageUpload = async (event) => {
        const _brandingLogoSupportedImageTypes = ["image/png"];
        const uploadAPIRoute = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-file`;
        const brandingUploadAPIRoute = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-content-badge`;
        const supportedImageTypes = ["image/jpeg", "image/png"];
        const fileName = event.target.files[0]?.name;
        await imageHandlerUtil({
            event,
            supportedImageTypes,
            maxSize: 1048576 * 5, // Max size 5MB
            uploadAPIRoute: uploadAPIRoute,
            handleImageInputError: (id, message) => {
                console.log(`Error for ${id}: ${message}`);
            },
            handleUploadSuccess: (id, imageUrl) => {
                console.log(imageUrl, "imageUrlerer");
                setTemplateObj({ ...templateObj, background: { imageUrl: imageUrl, name: fileName } })
                setImage(imageUrl);
                setImageFileName(fileName);
            },
            getBase64,
        });
    }
    const getBase64 = async (files) => {
        if (files.length === 1) {
            return new Promise((resolve) => {
                let reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = async (e) => {
                    const ratio = await getRatio(e.target.result);
                    resolve([{ b64: reader.result, ratio }]);
                };
            });
        } else {
            const fileData = Object.values(files);
            const filePathsPromises = [];
            fileData.forEach((file) => {
                filePathsPromises.push(
                    new Promise((resolve) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async (e) => {
                            const ratio = await getRatio(e.target.result);
                            resolve({ b64: reader.result, ratio });
                        };
                    }),
                );
            });
            const filePaths = await Promise.all(filePathsPromises);
            return filePaths;
        }
    };

    const getRatio = async (url) => {
        console.log("URL rat", url);
        return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                let ratio = img.width / img.height;
                resolve(ratio);
            };
        });
    };

    const handleDeleteImage = () => {
        setImage(null);
        setImageFileName('');
        setTemplateObj({ ...templateObj, background: { ...templateObj.background, imageUrl: null, name: '' } });
    };

    const handleDeleteColor = () => {
        const defaultColor = '';
        setColor(defaultColor);
        setTemplateObj({ ...templateObj, background: { ...templateObj.background, backgroundColor: defaultColor } });
    };
    console.log(image,templateObj, "image");

    return (
        <div>
            <Button
                variant="outlined"
                onClick={handleMenuOpen}
                startIcon={<img src={BackgroundIcon} alt="form Icon" width={20} height={20} />}
                endIcon={<ArrowDropDownIcon />}
            >
                Background
            </Button>
            <Menu
                anchorEl={anchorEl}
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    aria-label="Tabs"
                    variant="fullWidth"
                    TabIndicatorProps={{ style: { display: 'none' } }}
                >
                    <Tab label="Image" sx={{ flex: 1 }} />
                    <Tab label="Color" sx={{ flex: 1 }} />
                </Tabs>
                <Divider />

                {selectedTab === 0 && (
                    <Box sx={{ padding: '10px', width: '300px' }}>
                        {/* Image Tab */}
                        {templateObj?.background?.imageUrl ? (
                            <Box>
                                <img
                                    src={templateObj?.background?.imageUrl}
                                    alt="Uploaded Preview"
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        maxHeight: '100px',
                                        objectFit: 'contain',
                                    }}
                                />
                                <div>{templateObj?.background?.name}</div>
                                <Divider />
                                <Button
                                    variant="outlined"
                                    onClick={handleReplaceImage}
                                    startIcon={<CloudUploadIcon />}
                                    style={{ marginTop: '10px' }}
                                >
                                    Replace Image
                                </Button>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={handleDeleteImage}
                                    startIcon={<DeleteIcon />}
                                    style={{ marginTop: '10px', marginLeft: '10px' }}
                                >
                                    Delete Image
                                </Button>
                            </Box>
                        ) : (
                            <Box>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    startIcon={<CloudUploadIcon />}
                                    style={{ width: '100%', marginBottom: '10px' }}
                                    disabled={Boolean(templateObj?.background?.backgroundColor)}
                                >
                                    Upload Image
                                    <input
                                        id='background-image'
                                        type="file"
                                        ref={fileInputRef} // Attach ref to file input
                                        accept="image/*"
                                        onChange={handleImageUpload}
                                        hidden
                                    />
                                </Button>
                                <Divider />
                                <div>No image uploaded yet</div>
                            </Box>
                        )}
                    </Box>
                )}

                {selectedTab === 1 && (
                    <Box sx={{ padding: '10px', width: '300px' }}>
                        {/* Color Tab */}
                        {console.log( templateObj?.background?.backgroundColor,"bgclr")}
                        <Box
                            sx={{
                                marginBottom: '10px',
                                height: '80px',
                                width: '100%',
                                backgroundColor: templateObj?.background?.backgroundColor,
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                            }}
                        />
                        <TextField
                            label="Select Color"
                            type="color"
                            value={templateObj?.background?.backgroundColor}
                            onChange={(e) => {
                                setColor(e.target.value);
                                setTemplateObj({ ...templateObj, background: { ...templateObj.background, backgroundColor: e.target.value } })
                            }}
                            fullWidth
                            disabled={Boolean(templateObj?.background?.imageUrl)}
                        />
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleDeleteColor}
                            startIcon={<DeleteIcon />}
                            style={{ marginTop: '10px' }}
                        >
                            Reset Color
                        </Button>
                    </Box>

                )}
            </Menu>

            {/* The hidden file input is only rendered once, ensuring the file dialog is opened when clicked */}
            <input
                id='image-replace'
                type="file"
                ref={fileInputRef}
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
            />
        </div>
    );
};

export default ImageColorDropdown;
