import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import SignInForm from '../Components/SignInForm';
import GoogleElement from '../Components/GoogleElement';
import FacebookElement from '../Components/FacebookElement';
import Carousel from '../Components/Carousel';

const PreviewScreen = ({
    fontSize = '1rem',
    fontColor = '#000',
    buttonColor = '#007bff',
    buttonTextColor = '#fff',
    templateObj
}) => {
 
    
    // Helper function to render logo or carousel based on componentType
    const renderLogoOrCarousel = () => {
        if (templateObj?.media?.type === "image" && templateObj?.media?.fileURL) {
            return (
                <img src={templateObj.media.fileURL} alt="Logo" style={{ width: '300px', height: 'auto' }} />
            );
        }
        if (templateObj?.media?.type  === "video" && templateObj?.media?.file) {
            return (
                <video
                src={templateObj.media.fileURL}
                style={{ width: '300px', height: 'auto' }}
                controls
                autoPlay
                loop
            >
                Your browser does not support the video tag.
            </video>
            );
        }
        if (templateObj?.media?.type  === "slideshow") {
            return (
                <Carousel templateObj={templateObj}/>
            );
        }
        return null;
    };

    // Helper function to render the form
    const renderForm = () => (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', gap: "1rem" }}>
            <SignInForm
                fontSize={fontSize}
                fontColor={fontColor}
                buttonColor={buttonColor}
                buttonTextColor={buttonTextColor}
                backgroundColor="#f0f0f0"
                onSignUpClick={() => console.log('Navigate to Sign Up')}
                templateObj={templateObj}
            />
             <Box
                sx={{
                    display: 'flex',
                    flexDirection: templateObj?.socialMedia?.settings?.order !== "Vertical" ? 'row' : 'column',
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px"
                }}
            >
                {templateObj?.socialMedia?.settings?.position === "Below Form" && templateObj?.socialMedia?.checkboxes?.google && <GoogleElement templateObj={templateObj} />}
                {templateObj?.socialMedia?.checkboxes?.facebook && templateObj?.socialMedia?.settings?.position === "Below Form" && <FacebookElement templateObj={templateObj} />}
            </Box>
            
        </Box>
    );

    return (
        <ScreenWrapper sx={{ backgroundImage: `url(${templateObj?.background?.imageUrl})`, backgroundColor: `${templateObj?.background?.backgroundColor}` }}>
            {/* Left Section */}
            <DynamicPositionBox alignPosition="left">
                {templateObj?.media?.position === 'Left' && renderLogoOrCarousel()}
                {templateObj?.form?.position === 'Left' && renderForm()}
            </DynamicPositionBox>

            {/* Center Section */}
            <DynamicPositionBox alignPosition="center">
                {templateObj?.media?.position === 'Center' && renderLogoOrCarousel()}
                {templateObj?.form?.position === 'Center' && renderForm()}
            </DynamicPositionBox>

            {/* Right Section */}
            <DynamicPositionBox alignPosition="right">
                {templateObj?.media?.position === 'Right' && renderLogoOrCarousel()}
                {templateObj?.form?.position === 'Right' && renderForm()}
            </DynamicPositionBox>
        </ScreenWrapper>
    );
};

// Styled Components
const ScreenWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100vw',
    padding: '10px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
});

const DynamicPositionBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'alignPosition',
})(({ alignPosition }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: alignPosition === 'center' ? 'center' : alignPosition === 'left' ? 'flex-start' : 'flex-end',
    justifyContent: 'center',
    width: '33%',
    textAlign: 'center',
}));

export default PreviewScreen;
