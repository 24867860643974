import React from "react";
import "./maintenance.css";
import { ReactComponent as BellIcon } from "../../assets/img/bellIcon.svg";

const MaintenanceNotification = ({ type, message }) => {
  const [description, date, timeRange] = message?.split(/on|From/)?.map((str) => str.trim());

  return (
    <div className="notification-bar">
      <div className="notification-content">
        <div className="notification-icon">
          <BellIcon className="bell-icon" />
        </div>
        <div className="notification-details">
          <h4 className="notification-title">
            {type.toUpperCase()} MAINTENANCE UPGRADE
          </h4>
          <p className="notification-description">
            <span className="highlight"> {date}</span>
            <span className="highlight" style={{ marginLeft: "5px" }}>
              {""}from {timeRange}
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceNotification;
