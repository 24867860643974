import React from 'react';
import { Box, styled } from '@mui/material';
import SignInForm from '../Components/SignInForm';
import GoogleElement from '../Components/GoogleElement';
import FacebookElement from '../Components/FacebookElement';
import Carousel from '../Components/Carousel';
import SimpleSlider from '../Components/MobileSlider';

const MobilePreviewScreen = ({
    fontSize = '1rem',
    fontColor = '#000',
    buttonColor = '#007bff',
    buttonTextColor = '#fff',
    templateObj
}) => {
    // Helper function to render logo or carousel based on component type
    const renderLogoOrCarousel = () => {
        if (templateObj?.media?.type === "image" && templateObj?.media?.fileURL) {
            return (
                <img
                    src={templateObj.media.fileURL}
                    alt="Logo"
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                    }}
                />
            );
        }
        if (templateObj?.media?.type === "video" && templateObj?.media?.fileURL) {
            return (
                <video
                    src={templateObj.media.fileURL}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                    }}
                    controls
                    autoPlay
                    loop
                >
                    Your browser does not support the video tag.
                </video>
            );
        }
        if (templateObj?.media?.type === "slideshow") {
            return <SimpleSlider templateObj={templateObj} />;
        }
        return null;
    };

    const renderForm = () => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                gap: '1rem',
                
            }}
        >
            <SignInForm
                fontSize={fontSize}
                fontColor={fontColor}
                buttonColor={buttonColor}
                buttonTextColor={buttonTextColor}
                backgroundColor="#f0f0f0"
                onSignUpClick={() => console.log('Navigate to Sign Up')}
                templateObj={templateObj}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: templateObj?.socialMedia?.settings?.order !== "Vertical" ? 'row' : 'column',
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                }}
            >
                {templateObj?.socialMedia?.settings?.position === "Below Form" &&
                    templateObj?.socialMedia?.checkboxes?.google && (
                        <GoogleElement templateObj={templateObj} />
                    )}
                {templateObj?.socialMedia?.checkboxes?.facebook &&
                    templateObj?.socialMedia?.settings?.position === "Below Form" && (
                        <FacebookElement templateObj={templateObj} />
                    )}
            </Box>
        </Box>
    );

    return (
        <MobileScreenWrapper>
            <MobilePreviewBox
             backgroundColor={templateObj?.background?.backgroundColor || '#fff'} 
             backgroundImage={templateObj?.background?.imageUrl}
            >
                <TopSection>
                    {templateObj?.form?.position === 'Top' && renderForm()}
                    {templateObj?.media?.position === "Top" && (
                        <CenteredContainer>{renderLogoOrCarousel()}</CenteredContainer>
                    )}
                    {(!templateObj?.media?.position || !templateObj?.form?.position) && <Placeholder />}
                </TopSection>
                {/* <BottomSection>
                    {templateObj?.form?.position === 'Bottom' && renderForm()}
                    {templateObj?.media?.position === "Bottom" && (
                        <CenteredContainer>{renderLogoOrCarousel()}</CenteredContainer>
                    )}
                    {(!templateObj?.settings?.bottomSection || !templateObj?.form?.position) && <Placeholder />}
                </BottomSection> */}
                <TopSection style={{padding:"20px"}}>
                    {templateObj?.form?.position === 'Bottom' && renderForm()}
                    {templateObj?.media?.position === "Bottom" && (
                        <CenteredContainer>{renderLogoOrCarousel()}</CenteredContainer>
                    )}
                    {(!templateObj?.media?.position || !templateObj?.form?.position) && <Placeholder />}
                </TopSection>
            </MobilePreviewBox>
        </MobileScreenWrapper>
    );
};

// Styled Components
const MobileScreenWrapper = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#CCDCFE',
    padding: '20px',
    width: '100%',
});

const MobilePreviewBox = styled(Box)(({ backgroundColor ,backgroundImage}) => ({
    width: '100%',
    maxWidth: '375px',
    background: backgroundImage 
        ? `url(${backgroundImage}) no-repeat center center/cover`
        : backgroundColor || '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    minHeight: '70vh',
}));

const TopSection = styled(Box)({
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'black',
});

const BottomSection = styled(Box)({
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    width: '100%',
});

const CenteredContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    textAlign: 'center',
    boxSizing: 'border-box',
});

const Placeholder = styled(Box)({
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
});

export default MobilePreviewScreen;
