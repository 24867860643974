import {
  AddBox,
  AddBoxRounded,
  AddCircle,
  Balance,
  EditOutlined,
  Expand,
  ExpandMore,
  Search,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Dialog,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  Input,
  Snackbar,
  Typography,
  FormControl
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import Select from "react-select";

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import {
  addNewJsonDetails,
  getAllPackages,
  updateJsonDetails,
} from "../actions";
import axios from "axios";
import { successfullyGetAllPackages } from "../actions";
import { styled } from '@mui/material/styles';
import { TextField } from "@material-ui/core";


const GreenCircleButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#3791EE',
  color: 'white',
  borderRadius: '50%',
  padding:0,
  '&:hover': {
    backgroundColor: '#186bc2',
  },
}));
const RedDeleteButton = styled(IconButton)(({ theme }) => ({
  // backgroundColor: '#3791EE',
  color: 'red',
  borderRadius: '50%',
  padding:0,
  '&:hover': {
    // backgroundColor: '#8abef4',
    color: '#d40707',
  },
}));

const Package = (props) => {
  const [expanded, setExpanded] = useState(-1);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [newPackage, setNewPackage] = useState({});
  const [all, setAll] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [editopen, setEditOpen] = useState(false);
  const [error, setError] = useState("");
  const [ovpSet, setOvpSet] = useState(null);
  const [allPackages,setAllPackages]=useState({});
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: 'transparent', // Change control background color
      borderColor: state.isFocused ? '#015ECC' : '#8297C2', // Green border when focused
      '&:hover': {
        borderColor: '#015ECC', // Border color on hover
      },
      // boxShadow: state.isFocused ? '0 0 5px #015ECC' : 'none', // Shadow effect when focused
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#f9f9f9', // Background color for the dropdown menu
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#4caf50' : '#fff', // Background color for selected option
      '&:hover': {
        backgroundColor: '#e0e0e0', // Hover background for options
      },
      color: state.isSelected ? '#fff' : '#000', // Text color for selected options
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: '#d1f7c4', // Background for multi-value selected items
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#4caf50', // Text color for multi-value labels
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#4caf50', // Color for remove icon
      '&:hover': {
        backgroundColor: '#ffcccb', // Hover background for remove icon
      },
    }),
  };
  useEffect(() => {
    console.log("getAllPackageseffect");
    const payload={
      p_id:props?.projectDetails?.p_id,
      t_id:props?.authToken?.t_id
    }
console.log(payload,"effect payload");

    props.getAllPackages(payload);
  }, [props.updateJson]);

  useEffect(() => {
    console.log("getAllPackageseffect");

    setAllPackages(props?.packageDetails)
  }, [props?.packageDetails]);

  useEffect(() => {
    const plansOvp = props?.packageDetails?.packages;
    if (plansOvp && plansOvp?.basePlan) {
      const selectedBasePlans = plansOvp?.basePlan?.map(field => field?.basePlanOvp);
      const selectedDurationPlans = plansOvp?.basePlan?.flatMap(field => field.durationPlanOvp || [])?.map(option => option.ovpSKU);
      const ovpSKUIds = new Set([...selectedBasePlans, ...selectedDurationPlans]);
      setOvpSet(ovpSKUIds);
      console.log(ovpSKUIds, "ovpId");
    }
  }, [props?.packageDetails])
  const handleExpand = (x, i) => {
    setExpanded(expanded === i ? -1 : i);
  };

  const handleAddNewPackage = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getBase64 = async (file) => {
    return new Promise((resolve) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
    });
  };

  const handlePackageImage = async (event, type, name, ratio, id, index) => {
    const files = event.target.files;
    const file = files[0];
    const ext = file.type.split("/")[1];
    const images = await getBase64(file);
    if (typeof images != undefined) {
      const options = {
        type: type,
        name: name,
        skuId: id,
        aspectRatio: ratio === "AR1" ? "16x9" : ratio === "AR2" ? "9x14" : "",
        imageBase64: images,
      };
      const S3image = await axios.post(
        `https://api.xpcloudapp.com/dev/v1/subscription/upload?p_id=${props?.projectDetails?.p_id}`,
        options,
        {
          headers: {
            "x-api-key":process.env.REACT_APP_X_API_KEY,
            Authorization: localStorage.getItem("token")
          },
        }
      );
      console.log(S3image,"S3image");
      
      // props?.packageDetails?.packages.map((x, i) => {
      //   if (i === index) {
      //     if (ratio === "AR1") {
      //       if (name === "Subscription_web_tab") {
      //         x.web_tab_images.image_url = S3image?.data?.uploadedUrl;
      //       } else if (name === "promo_web_tab") {
      //         x.web_tab_images.promo_image_url = S3image?.data?.uploadedUrl;
      //       } else if (name === "fallback_web_tab") {
      //         x.web_tab_images.fallback_image_url = S3image?.data?.uploadedUrl;
      //       }
      //       else if (name === "Subscription_tablet") {
      //         x.tablet_images.image_url = S3image?.data?.uploadedUrl;
      //       } else if (name === "promo_tablet") {
      //         x.tablet_images.promo_image_url = S3image?.data?.uploadedUrl;
      //       } else if (name === "fallback_tablet") {
      //         x.tablet_images.fallback_image_url = S3image?.data?.uploadedUrl;
      //       }
      //     } else if (ratio === "AR2") {
      //       if (name === "Subscription_mobile") {
      //         x.mobile_images.image_url = S3image.data.uploadedUrl;
      //       } else if (name === "promo_mobile") {
      //         x.mobile_images.promo_image_url = S3image.data.uploadedUrl;
      //       } else if (name === "fallback_mobile") {
      //         console.log("images");
      //         x.mobile_images.fallback_image_url = S3image.data.uploadedUrl;
      //       } else if (name === "Subscription_mweb") {
      //         x.mweb_images.image_url = S3image.data.uploadedUrl;
      //       } else if (name === "promo_mweb") {
      //         x.mweb_images.promo_image_url = S3image.data.uploadedUrl;
      //       } else if (name === "fallback_mweb") {
      //         console.log("images");
      //         x.mweb_images.fallback_image_url = S3image.data.uploadedUrl;
      //       }
      //     } else if (ratio === "learnmore") {
      //       if (name === "Subscription") {
      //         x.learn_more_image_url = S3image.data.uploadedUrl;
      //       } else if (name === "fallback") {
      //         x.learn_more_fallback_image_url = S3image.data.uploadedUrl;
      //       }
      //     }
      //   }
      // });
      setAllPackages((prev) => ({
        ...prev,
        packages: prev?.packages.map((pkg, i) => {
          if (i === index) {
            if (ratio === "AR1") {
              if (name === "Subscription_web_tab") {
                return { ...pkg, web_tab_images: { ...pkg.web_tab_images, image_url: S3image?.data?.uploadResult?.Location } };
              } else if (name === "promo_web_tab") {
                return { ...pkg, web_tab_images: { ...pkg.web_tab_images, promo_image_url: S3image?.data?.uploadResult?.Location} };
              } else if (name === "fallback_web_tab") {
                return { ...pkg, web_tab_images: { ...pkg.web_tab_images, fallback_image_url: S3image?.data?.uploadResult?.Location } };
              } else if (name === "Subscription_tablet") {
                return { ...pkg, tablet_images: { ...pkg.tablet_images, image_url: S3image?.data?.uploadResult?.Location } };
              } else if (name === "promo_tablet") {
                return { ...pkg, tablet_images: { ...pkg.tablet_images, promo_image_url: S3image?.data?.uploadResult?.Location } };
              } else if (name === "fallback_tablet") {
                return { ...pkg, tablet_images: { ...pkg.tablet_images, fallback_image_url: S3image?.data?.uploadResult?.Location } };
              }
            } else if (ratio === "AR2") {
              if (name === "Subscription_mobile") {
                return { ...pkg, mobile_images: { ...pkg.mobile_images, image_url: S3image.data?.uploadResult?.Location } };
              } else if (name === "promo_mobile") {
                return { ...pkg, mobile_images: { ...pkg.mobile_images, promo_image_url: S3image.data?.uploadResult?.Location } };
              } else if (name === "fallback_mobile") {
                return { ...pkg, mobile_images: { ...pkg.mobile_images, fallback_image_url: S3image.data?.uploadResult?.Location } };
              } else if (name === "Subscription_mweb") {
                return { ...pkg, mweb_images: { ...pkg.mweb_images, image_url: S3image.data?.uploadResult?.Location } };
              } else if (name === "promo_mweb") {
                return { ...pkg, mweb_images: { ...pkg.mweb_images, promo_image_url: S3image.data?.uploadResult?.Location } };
              } else if (name === "fallback_mweb") {
                return { ...pkg, mweb_images: { ...pkg.mweb_images, fallback_image_url: S3image.data?.uploadResult?.Location } };
              }
            } else if (ratio === "learnmore") {
              if (name === "Subscription") {
                return { ...pkg, learn_more_image_url: S3image.data?.uploadResult?.Location };
              } else if (name === "fallback") {
                return { ...pkg, learn_more_fallback_image_url: S3image.data?.uploadResult?.Location };
              }
            }
          }
          return pkg; 
        }),
      }));
    
    }

    // props.successfullyGetAllPackages(props?.packageDetails?.packages);
  };

  const handleSave = (x, i) => {
    console.log(x,"xxx");
    
    if (x.ovpSKU === undefined || x.ovpSKU === "") {
      setOpenSnackbar(true);
    } else if (
      x.learn_more_image_url === undefined ||
      x.learn_more_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.learn_more_fallback_image_url === undefined ||
      x.learn_more_fallback_image_url === ""
    ) {
      setOpenSnackbar(true);
    }
    //  else if (
    //   x.is_manually_inserted === undefined ||
    //   x.is_manually_inserted === ""
    // ) {
    //   setOpenSnackbar(true);
    // }
     else if (
      x.web_tab_images.image_url === undefined ||
      x.web_tab_images.image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.web_tab_images.fallback_image_url === undefined ||
      x.web_tab_images.fallback_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.web_tab_images.promo_image_url === undefined ||
      x.web_tab_images.promo_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.mobile_images.image_url === undefined ||
      x.mobile_images.image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.mobile_images.promo_image_url === undefined ||
      x.mobile_images.promo_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.mobile_images.fallback_image_url === undefined ||
      x.mobile_images.fallback_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.tablet_images.image_url === undefined ||
      x.tablet_images.image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.tablet_images.promo_image_url === undefined ||
      x.tablet_images.promo_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.tablet_images.fallback_image_url === undefined ||
      x.tablet_images.fallback_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.mweb_images.image_url === undefined ||
      x.mweb_images.image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.mweb_images.promo_image_url === undefined ||
      x.mweb_images.promo_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      x.mweb_images.fallback_image_url === undefined ||
      x.mweb_images.fallback_image_url === ""
    ) {
      setOpenSnackbar(true);
    }
     else {
      const payload = {allPackages:allPackages, p_id:props?.projectDetails?.p_id,t_id:props?.authToken?.t_id}
      props.updateJsonDetails(payload);
      setExpanded(-1);
      setEditOpen(false);
    }
  };
  function checkConditions(dataArray) {
    console.log(dataArray,"dat")
    for (let i = 0; i < dataArray?.length; i++) {
      const item = dataArray[i];
      console.log(item,"itemitem")
      if (item.basePlanOvp === null || item.basePlanOvp === undefined) {
        return true;
      }
      if (item.durationPlanOvp.length === 0 || item.durationPlanOvp.some(entry => entry === null || entry === undefined)) {
        return true;
      }
    }
    return false;
  }

  const handleUpdateBasePlanSave = () => {
    if (
      !config
    ) {
      setOpenSnackbar(true);
    } 
    // commented for now
    // else if (   
    //   config?.mweb_subscription_tab_1_label === "" ||
    //   config?.mweb_subscription_tab_1_label === undefined
    // ) {
    //   setOpenSnackbar(true);
    // } else if (
    //   config?.mweb_subscription_tab_2_label === "" ||
    //   config?.mweb_subscription_tab_2_label === undefined
    // ) {
    //   setOpenSnackbar(true);
    // } else if (
    //   config?.mweb_subscription_tab_3_label === "" ||
    //   config?.mweb_subscription_tab_3_label === undefined
    // ) {
    //   setOpenSnackbar(true);
    // } else if (
    //   config?.mweb_subscription_tab_4_label === "" ||
    //   config?.mweb_subscription_tab_4_label === undefined
    // ) {
    //   setOpenSnackbar(true);
    // } else if (
    //   !config?.mweb_subscription_tab_1_ids  ||
    //   config?.mweb_subscription_tab_1_ids === undefined
    // ) {
    //   setOpenSnackbar(true);
    // } else if (
    //   !config?.mweb_subscription_tab_2_ids ||
    //   config?.mweb_subscription_tab_2_ids === undefined
    // ) {
    //   setOpenSnackbar(true);
    // }  else if (
    //   !config.mweb_subscription_tab_3_ids ||
    //   config.mweb_subscription_tab_3_ids === undefined
    // ) {
    //   setOpenSnackbar(true);
    // } else if (
    //   !config.mweb_subscription_tab_4_ids ||
    //   config.mweb_subscription_tab_4_ids === undefined
    // ) {
    //   setOpenSnackbar(true);
    // } else if (checkConditions(config?.basePlan)){
    //   setOpenSnackbar(true);
    // }
     else {
      console.log(config,"submitconfig");
      const newConfig= {...config,packages:allPackages?.packages}
      const payload = {allPackages:newConfig, p_id:props?.projectDetails?.p_id,t_id:props?.authToken?.t_id}
      console.log(newConfig,"newConfig")
      props.updateJsonDetails(payload);
    }
  };

  //new package functions

  useEffect(() => {
    setNewPackage({ ...newPackage, is_manually_inserted: true });
  }, []);

  useEffect(() => {
    if (newPackage.ovpSKU) {
      setAll([
        ...props?.packageDetails?.packages,
        newPackage,
      ]);
    }
  }, [newPackage]);

  const handleOvpSKU = (event) => {

    const value = event.target.value;
    const numericValue = Number(value); 
    if (!isNaN(numericValue)) { 
      setNewPackage({ ...newPackage, ovpSKU: numericValue });
      console.log(newPackage, "newPackage updated");
  
      setError("");
    } else {
      setError("Please enter number only");
    }
  };
  const handleCreatePlanTitle = (event) => {
    const value = event.target.value;
    if (value) {
      setNewPackage({ ...newPackage, plan_title: value });
    } else {
      setNewPackage({ ...newPackage, plan_title: "" });
    }
  }

  const handleCreatePlanDescription = (event) => {
    const value = event.target.value;
    if (value) {
      setNewPackage({ ...newPackage, plan_description: value });
    } else {
      setNewPackage({ ...newPackage, plan_description: "" });
    }
  }

  const handleAddNewPackageUpdate = () => {
    if (newPackage.ovpSKU === undefined || newPackage.ovpSKU === "") {
      setOpenSnackbar(true);
    } else if (
      newPackage.learn_more_image_url === undefined ||
      newPackage.learn_more_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.learn_more_fallback_image_url === undefined ||
      newPackage.learn_more_fallback_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.is_manually_inserted === undefined ||
      newPackage.is_manually_inserted === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.web_tab_images.image_url === undefined ||
      newPackage.web_tab_images.image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.web_tab_images.fallback_image_url === undefined ||
      newPackage.web_tab_images.fallback_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.web_tab_images.promo_image_url === undefined ||
      newPackage.web_tab_images.promo_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.mobile_images.image_url === undefined ||
      newPackage.mobile_images.image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.mobile_images.promo_image_url === undefined ||
      newPackage.mobile_images.promo_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.mobile_images.fallback_image_url === undefined ||
      newPackage.mobile_images.fallback_image_url === ""
    ) {
      setOpenSnackbar(true);
    }
    else if (
      newPackage.tablet_images.image_url === undefined ||
      newPackage.tablet_images.image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.tablet_images.promo_image_url === undefined ||
      newPackage.tablet_images.promo_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.tablet_images.fallback_image_url === undefined ||
      newPackage.tablet_images.fallback_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.mweb_images.image_url === undefined ||
      newPackage.mweb_images.image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.mweb_images.promo_image_url === undefined ||
      newPackage.mweb_images.promo_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (
      newPackage.mweb_images.fallback_image_url === undefined ||
      newPackage.mweb_images.fallback_image_url === ""
    ) {
      setOpenSnackbar(true);
    } else if (newPackage?.plan_title === undefined || newPackage?.plan_title === " ") {
      setOpenSnackbar(true);
    } else if (newPackage?.plan_description === undefined || newPackage?.plan_description === " ") {
      setOpenSnackbar(true);
    } else {
      console.log(all,"all")
      const newConfig= {...config,packages:all}
      const payload = {allPackages:newConfig, p_id:props?.projectDetails?.p_id,t_id:props?.authToken?.t_id}

      props.updateJsonDetails(payload);
      setOpenDialog(false);
    }
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleNewPackageImage = async (event, type, name, ratio, id, index) => {
    console.log("event", type, name, ratio, id,newPackage);
    if (id === undefined || id === "") {
      console.log("error");
      setOpenSnackbar(true);
    } else {
      const files = event.target.files;
      const file = files[0];
      const ext = file.type.split("/")[1];
      const images = await getBase64(file);
      if (typeof images != undefined) {
        const options = {
          type: type,
          name: name,
          skuId: id,
          aspectRatio: ratio === "AR1" ? "16x9" : ratio === "AR2" ? "9x14" : "",
          imageBase64: images,
        };
        const S3image = await axios.post(`https://api.xpcloudapp.com/dev/v1/subscription/upload?p_id=${props?.projectDetails?.p_id}`,
          options,
          {
            headers: {
              "x-api-key":process.env.REACT_APP_X_API_KEY,
              Authorization: localStorage.getItem("token")
            },
          }
        );
        console.log(S3image,"S3image");
        

        //imageUpload=S3image.data.uploadResult.Location
        //setContestDetails({...contestDetails,bannerUrl:S3image.data.data})
        if (ratio === "AR1") {
          if (name === "Subscription_web_tab") {
            // web and tab
            setNewPackage({
              ...newPackage,
              web_tab_images: {
                ...newPackage?.web_tab_images,
                image_url: S3image?.data?.uploadResult?.Location,
              },
            });
          } else if (name === "promo_web_tab") {
            setNewPackage({
              ...newPackage,
              web_tab_images: {
                ...newPackage?.web_tab_images,
                promo_image_url: S3image.data?.uploadResult?.Location,
              },
            });
          } else if (name === "fallback_web_tab") {
            setNewPackage({
              ...newPackage,
              web_tab_images: {
                ...newPackage?.web_tab_images,
                fallback_image_url: S3image.data?.uploadResult?.Location,
              },
            });
          } else if (name === "Subscription_tablet") {
            // tablet
            setNewPackage({
              ...newPackage,
              tablet_images: {
                ...newPackage?.tablet_images,
                image_url: S3image.data?.uploadResult?.Location,
              },
            });
          } else if (name === "promo_tablet") {
            setNewPackage({
              ...newPackage,
              tablet_images: {
                ...newPackage?.tablet_images,
                promo_image_url: S3image.data?.uploadResult?.Location,
              },
            });
          } else if (name === "fallback_tablet") {
            setNewPackage({
              ...newPackage,
              tablet_images: {
                ...newPackage?.tablet_images,
                fallback_image_url: S3image.data?.uploadResult?.Location,
              },
            });
          }
        } else if (ratio === "AR2") {
          if (name === "Subscription_mobile") {
            setNewPackage({
              ...newPackage,
              mobile_images: {
                ...newPackage.mobile_images,
                image_url: S3image.data?.uploadResult?.Location,
              },
            });
          } else if (name === "promo_mobile") {
            // mobile
            setNewPackage({
              ...newPackage,
              mobile_images: {
                ...newPackage.mobile_images,
                promo_image_url: S3image.data?.uploadResult?.Location,
              },
            });
          } else if (name === "fallback_mobile") {
            setNewPackage({
              ...newPackage,
              mobile_images: {
                ...newPackage.mobile_images,
                fallback_image_url: S3image.data?.uploadResult?.Location,
              },
            });
          } else if (name === "Subscription_mweb") {
            // mweb
            setNewPackage({
              ...newPackage,
              mweb_images: {
                ...newPackage.mweb_images,
                image_url: S3image.data?.uploadResult?.Location,
              },
            });
          } else if (name === "promo_mweb") {
            setNewPackage({
              ...newPackage,
              mweb_images: {
                ...newPackage.mweb_images,
                promo_image_url: S3image.data?.uploadResult?.Location,
              },
            });
          } else if (name === "fallback_mweb") {
            // mobileweb
            setNewPackage({
              ...newPackage,
              mweb_images: {
                ...newPackage.mweb_images,
                fallback_image_url: S3image.data?.uploadResult?.Location,
              },
            });
          }
        } else if (ratio === "learnmore") {
          console.log("kk", name);
          if (name === "Subscription") {
            setNewPackage({
              ...newPackage,
              learn_more_image_url: S3image.data?.uploadResult?.Location,
            });
          } else if (name === "fallback") {
            console.log("fallback");
            setNewPackage({
              ...newPackage,
              learn_more_fallback_image_url: S3image.data?.uploadResult?.Location,
            });
          }
        }
      }
    }
  };

  const handleFocus = (event) => {
    props?.packageDetails?.packages?.map((x, index) => {
      for (let i = 0; i <= index; i++) {
        if (x.ovpSKU == newPackage?.ovpSKU) {
          console.log("same");
          setError("id already exit");
          return;
        }
      }
    });
  };

  const handleEditOvpSKU = (event, x, index) => {
    // props?.packageDetails?.packages?.map((x, i) => {
    //   if (i === index) {
    //     const editValue = parseInt(event.target.value);
    //     x.ovpSKU = editValue;
    //   }
    // });
    const newSKU = event.target.value;
    setAllPackages((prev) => ({
      ...prev, // Keep other properties
      packages: prev.packages.map((pkg, i) => {
        if (i === index) {
          return {
            ...pkg, // Spread existing package properties
            ovpSKU: newSKU, // Update the ovpSKU
          };
        }
        return pkg; // Return unchanged package
      }),
    }));
    // props.successfullyGetAllPackages(props?.packageDetails?.packages);
  };

  const handleEditOpen = (x, i) => {
    setExpanded(expanded === i ? -1 : i);
    setEditOpen(true);
  };

  const handleChangePlanTitle = (event, x, index) => {
    // props?.packageDetails?.packages?.map((x, index) => {
    //   if (i === index) {
    //     const editValue = event.target.value;
    //     x.plan_title = editValue;
    //   }
    // });
    const newTitle = event.target.value;
    setAllPackages((prev) => ({
      ...prev, // Keep other properties
      packages: prev.packages.map((pkg, i) => {
        if (i === index) {
          return {
            ...pkg, // Spread existing package properties
            plan_title: newTitle, // Update the plan_title
          };
        }
        return pkg; // Return unchanged package
      }),
    }));
  
    // props.successfullyGetAllPackages(props?.packageDetails?.packages);
  }
  const handleChangePlanDescription = (event, x, index) => {
    // props?.packageDetails?.packages?.map((x, index) => {
    //   if (i === index) {
    //     const editValue = event.target.value;
    //     x.plan_description = editValue;
    //   }
    // });
    const newDescription = event.target.value;

    setAllPackages((prev) => ({
      ...prev, // Keep other properties
      packages: prev.packages.map((pkg, i) => {
        if (i === index) {
          return {
            ...pkg, // Spread existing package properties
            plan_description: newDescription, // Update the plan_description
          };
        }
        return pkg; // Return unchanged package
      }),
    }));
    // props.successfullyGetAllPackages(props?.packageDetails?.packages);
  }

  // Base plan
  const [fields, setFields] = useState([]);
  const [config, setConfig] = React.useState();
  const [ovpSkus, setOvpSkus] = useState([]);

  useEffect(() => {
    const configJson = props?.packageDetails;
    const package_details=props?.packageDetails?.packages;
    console.log(configJson,"configJson");
    
    if (configJson) {
      setConfig(configJson);
    }
   if (package_details?.length && package_details) {
    let updatedList = [];
    package_details?.map((list) => {
     if (list.ovpSKU=== undefined) {
      console.log("undefined");
     } else {
      updatedList.push({ ovpSKU: list.ovpSKU });
     }
    });
    setOvpSkus([...updatedList]);
   }
  }, [props?.packageDetails?.packages]);
  useEffect(() => {
    console.log(config,"fect2config");
    
    if (config && config?.basePlan) {
      setFields(config?.basePlan.map(bp => ({
        basePlanOvp: bp.basePlanOvp || null,
        durationPlanOvp: bp.durationPlanOvp ? bp.durationPlanOvp.map(option => (option )) : []
      })));
      
    } else {
      setFields([]);
    }
  }, [config?.basePlan]);

  const getAvailableDurationPlanOptions = (index) => {
    
    if (!config) return [];
    const selectedBasePlans = fields.map(field => field.basePlanOvp);
    const selectedDurationPlans = fields
      .flatMap(field => field.durationPlanOvp || [])
      .map(option => option);
    const selectedSet = new Set([
      ...config.mweb_subscription_tab_1_ids || [],
      ...config.mweb_subscription_tab_2_ids || [],
      ...config.mweb_subscription_tab_3_ids || [],
      ...config.mweb_subscription_tab_4_ids || [],
      ...selectedBasePlans,
      ...selectedDurationPlans
    ]);
    return ovpSkus.filter(obj => !selectedSet.has(obj.ovpSKU));
  };
  const getAvailableBasePlanOptions = (index) => {
    console.log(fields,config,">>>>>DdDD");

    if (!config) return [];
    const selectedDurationPlans = fields
      .flatMap(field => field.durationPlanOvp || [])
      .map(option => option);
    const selectedBasePlans = fields.map(field => field.basePlanOvp);
    const selectedSet = new Set([
      ...config.mweb_subscription_tab_1_ids || [],
      ...config.mweb_subscription_tab_2_ids || [],
      ...config.mweb_subscription_tab_3_ids || [],
      ...config.mweb_subscription_tab_4_ids || [],
      ...selectedDurationPlans,
      ...selectedBasePlans
    ]);
    return ovpSkus.filter(obj => !selectedSet.has(obj.ovpSKU)
    );
  };

  const handleBaseplanChange = (index, selectedOption) => {
    
    const newFields = [...fields];
    newFields[index].basePlanOvp = selectedOption || null;
    setFields(newFields);
    setConfig({ ...config, basePlan: newFields });
  };

  const handleAddField = () => {
    setFields([...fields, { basePlanOvp: null, durationPlanOvp: [] }]);
    setConfig({ ...config, basePlan: [...fields, { basePlanOvp: null, durationPlanOvp: [] }] });
  };

  const handleRemoveField = (index) => {
    const removedValue = fields[index].basePlanOvp;
    const newFields = fields.filter((_, idx) => idx !== index);
    setFields(newFields.map(field => {
      const newDurationPlanOvp = field.durationPlanOvp.filter(option => option !== removedValue);
      return { ...field, durationPlanOvp: newDurationPlanOvp };
    }));
    setConfig({ ...config, basePlan: newFields });
  };

  const handleSelectChange = (index, selectedOptions) => {
    console.log(selectedOptions,"selectedOptions")
    const newFields = [...fields];
    const selectedOvpSKUs = selectedOptions ? selectedOptions.map(option => option.ovpSKU) : [];
    // newFields[index].durationPlanOvp = selectedOptions || [];
    newFields[index].durationPlanOvp = selectedOvpSKUs || [];
    setFields(newFields);
    setConfig({ ...config, basePlan: newFields });
  };

  const handleChangemWebTabTitle = (event, type) => {
    console.log(event, type, "handleChangemWebTab2");
    if (type === "mweb_subscription_tab_1_label") {
      const value=event.target.value;
      // const validatedValue=value.replace(/^\s+|[^a-zA-Z _&-]/g, '');
      const validatedValue=value.replace(/^\s+/, '');
      setConfig({
        ...config,
        mweb_subscription_tab_1_label: validatedValue,
      });
    } else if (type === "mweb_subscription_tab_2_label") {
      console.log("handleChangemWebTab2");
      const value=event.target.value;
      const validatedValue=value.replace(/^\s+/, '');
      setConfig({
        ...config,
        mweb_subscription_tab_2_label: validatedValue,
      });
    } else if (type === "mweb_subscription_tab_3_label") {
      console.log("handleChangemWebTab2");
      const value=event.target.value;
      const validatedValue=value.replace(/^\s+/, '');
      setConfig({
        ...config,
        mweb_subscription_tab_3_label: validatedValue,
      });
    } else if (type === "mweb_subscription_tab_4_label") {
      console.log("handleChangemWebTab2");
      const value=event.target.value;
      const validatedValue=value.replace(/^\s+/, '');
      setConfig({
        ...config,
        mweb_subscription_tab_4_label: validatedValue,
      });
    }
  };
  const handleChangeHyperLink = (event, type) => {
    if (type === "mweb_subscription_tab_1_hyperlink") {
        const value = event.target.value;
        const str = value.replace(/^\s+/, '');
        setConfig({
            ...config,
            mweb_subscription_tab_1_hyperlink: str,
        });
    } else if (type === "mweb_subscription_tab_2_hyperlink") {
        const value = event.target.value
        const str = value.replace(/^\s+/, '');
        setConfig({
            ...config,
            mweb_subscription_tab_2_hyperlink: str,
        });
    } else if (type === "mweb_subscription_tab_3_hyperlink") {
        const value = event.target.value
        const str = value.replace(/^\s+/, '');
        setConfig({
            ...config,
            mweb_subscription_tab_3_hyperlink: str,
        });
    } else if (type === "mweb_subscription_tab_4_hyperlink") {
        const value = event.target.value
        const str = value.replace(/^\s+/, '');
        setConfig({
            ...config,
            mweb_subscription_tab_4_hyperlink: str,
        });
    }
};
const getAvailableOptions = (index) => {
  const selectedBasePlans = fields.map(field => field.basePlanOvp).filter(Boolean);
  const selectedDurationPlans = fields.flatMap(field => field.durationPlanOvp).map(option => option);
  if (
    !config ||
    !config.mweb_subscription_tab_1_ids ||
    !config.mweb_subscription_tab_2_ids ||
    !config.mweb_subscription_tab_3_ids ||
    !config.mweb_subscription_tab_4_ids
  ) {
    return [];
  }

  const selectedIds = [
    ...config.mweb_subscription_tab_1_ids,
    ...config.mweb_subscription_tab_2_ids,
    ...config.mweb_subscription_tab_3_ids,
    ...config.mweb_subscription_tab_4_ids,
    ...selectedDurationPlans
  ];
  // const selectedSet = new Set(selectedArray.flat());
  const selectedSet = new Set(selectedIds);

  return ovpSkus.filter(
   obj =>
    !selectedSet.has(obj.ovpSKU)
    // commented code for future use  
    // ||(fields[index]?.basePlanOvp === obj.ovpSKU || fields[index]?.durationPlanOvp?.some(option => option.ovpSKU === obj.ovpSKU))
    // fields[index]?.durationPlanOvp?.some(option => option.ovpSKU === obj.ovpSKU)
  );
};
const handleChange = (index, selectedOptions) => {
  if (index === 0) {
    let updatedValues = [...config.mweb_subscription_tab_1_ids];
    updatedValues = selectedOptions.map((x) => x.ovpSKU);
    setConfig({ ...config, mweb_subscription_tab_1_ids: updatedValues });
    console.log(updatedValues, "updatedVdddalues");
  } else if (index === 1) {
    let updatedValues = [...config.mweb_subscription_tab_2_ids];
    updatedValues = selectedOptions.map((x) => x.ovpSKU);
    setConfig({ ...config, mweb_subscription_tab_2_ids: updatedValues });
  } else if (index === 2) {
    let updatedValues = [...config.mweb_subscription_tab_3_ids];
    updatedValues = selectedOptions.map((x) => x.ovpSKU);
    setConfig({ ...config, mweb_subscription_tab_3_ids: updatedValues });
  } else if (index === 3) {
    let updatedValues = [...config.mweb_subscription_tab_4_ids];
    updatedValues = selectedOptions.map((x) => x.ovpSKU);
    setConfig({ ...config, mweb_subscription_tab_4_ids: updatedValues });
  }

  console.log(config.vip_tv_ids, "afterSelect");

};
  console.log(props.packageDetails,allPackages, "packageDetails")
  console.log(newPackage,"newPackage");
  console.log(props.projectDetails,props.authToken,"proconf");
  console.log(props.updateJson,"updateJson");
  
  return (
    <div>
      <Box pt={2} pb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <Card sx={{
              backgroundColor: "transparent"
            }}>
              <Box
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Typography variant="h6" color="black">
                  Packages
                </Typography>
              </Box>
              <Box px={4} py={1} display="flex" justifyContent="space-between">
                <Box display="flex" gap={1}></Box>
                <Box display="flex" justifyContent="flex-end" gap={1}>
                  {/* <IconButton>
                            <Search/>
                        </IconButton> */}
                  <IconButton onClick={handleAddNewPackage}>
                    <AddIcon
                      color="white"
                      sx={{ backgroundColor: "#ffff", borderRadius: "20px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
              <Box
                sx={{
                  overflow: "auto",
                  maxHeight: "max-content",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },

                }}
              >
                {allPackages?.packages?.map(
                  (x, i) => {
                    return (
                      <Box py={1} px={2} key={i}>
                        <Card
                          sx={{
                            border: "2px solid rgba(199, 199, 199, 0.3)",
                            backgroundColor: "transparent"
                          }}
                          elevation={0}
                        >
                          <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                          >
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="space-around"
                            >
                              <Box
                                paddingTop="10px"
                                paddingBottom="10px"
                                px={1}
                              >
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  alignContent="space-between"
                                  justifyContent="space-between"
                                >
                                  <Box>
                                    {editopen && expanded === i ? (
                                      <>
                                        <Typography
                                          variant="caption"
                                          sx={{ fontSize: "14px" }}
                                          color="dark"
                                          fontWeight="regular"
                                        >
                                          ovpSKU
                                        </Typography>
                                        <TextField
                                          sx={{
                                            // width: "40%",
                                            paddingLeft: "20px",
                                          }}
                                          style={{  width: "40%",}}
                                          size="small"
                                          onChange={(event) =>
                                            handleEditOvpSKU(event, x, i)
                                          }
                                          value={x.ovpSKU}
                                          variant="outlined"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <Typography
                                          variant="caption"
                                          color="dark"
                                          fontWeight="regular"
                                          sx={{
                                            width: "180px",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                          }}
                                        >
                                          OVP SKU Id : {x.ovpSKU}
                                        </Typography>
                                        {x.is_manually_inserted === true ? (
                                          <IconButton
                                            onClick={() => handleEditOpen(x, i)}
                                          >
                                            <EditOutlined />
                                          </IconButton>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </Box>
                                  <Typography
                                    variant="caption"
                                    color="dark"
                                    fontWeight="regular"
                                    sx={{ width: "300px", fontSize: "14px" }}
                                  >
                                    Package Name : {x.package_name}
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    color="dark"
                                    fontWeight="regular"
                                    sx={{ width: "300px", fontSize: "14px" }}
                                  >
                                    Last Modified : {x.last_modified_time_utc}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                            <Box
                              pl={40}
                              py={1}>
                              {(ovpSet instanceof Set && ovpSet?.has(x?.ovpSKU)) && <Typography style={{
                                color: "white", fontSize: "11px", background: "#3892EE", padding: "9px", borderRadius: ".5rem"
                              }} key={i}>Baseplan or Standaloneplan</Typography>}

                            </Box>
                            <Box
                              px={10}
                              py={1}
                              onClick={() => handleExpand(x, i)}
                            >
                              <ExpandMore />
                            </Box>
                          </Box>
                          <Collapse in={expanded === i}>
                            <CardContent>
                              <Box display="flex"
                                gap={1}
                                justifyContent="space-between"
                                padding="10px"
                                flexDirection="column"
                                color="#344767"
                              >
                                <>
                                  <Typography
                                    variant="caption"
                                    sx={{ fontSize: "1.25rem" }}
                                    color="dark"
                                    fontWeight="400"
                                  >
                                    Plan Title
                                  </Typography>
                                  <TextField
                                    style={{  width: "40%",}}
                                    size="small"
                                    onChange={(event) =>
                                      handleChangePlanTitle(event, x, i)
                                    }
                                    value={x.plan_title}
                                    variant="outlined"
                                  />

                                </>
                                <>
                                  <Typography
                                    variant="caption"
                                    sx={{ fontSize: "1.25rem" }}
                                    color="dark"
                                    fontWeight="400"
                                  >
                                    Plan Description
                                  </Typography>
                                  <TextField
                                    style={{  width: "40%",}}
                                    size="small"
                                    onChange={(event) =>

                                      handleChangePlanDescription(event, x, i)

                                    }
                                    value={x.plan_description}
                                    variant="outlined"
                                  />
                                </>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography>
                                  Subscription (Web and Tablet images,16:9)
                                </Typography>
                                <Box
                                  py={1}
                                  px={1}
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Main
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "Subscription_web_tab",
                                          "AR1",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x?.web_tab_images.image_url !== "" ? (
                                      <img
                                        src={
                                          x?.web_tab_images.image_url !== ""
                                            ? `${x?.web_tab_images.image_url
                                            }?${Date.now()}`
                                            : null
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Promo
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "promo_web_tab",
                                          "AR1",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {console.log(x.web_tab_images.promo_image_url,"x.web_tab_images.promo_image_url")
                                    }
                                    {x.web_tab_images.promo_image_url !== "" ? (
                                      <img
                                        src={
                                          x.web_tab_images.promo_image_url !==
                                            ""
                                            ? `${x.web_tab_images.promo_image_url
                                            }?${Date.now()}`
                                            : null
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Fallback
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "fallback_web_tab",
                                          "AR1",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x.web_tab_images.fallback_image_url !==
                                      "" ? (
                                      <img
                                        src={
                                          x.web_tab_images.fallback_image_url
                                            ? `${x.web_tab_images
                                              .fallback_image_url
                                            }?${Date.now()}`
                                            : ""
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                py={1}
                                px={1}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography>
                                  Subscription (Mobile images,9:14.2)
                                </Typography>
                                <Box
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Main
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "Subscription_mobile",
                                          "AR2",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x.mobile_images.image_url !== "" ? (
                                      <img
                                        src={
                                          x.mobile_images.image_url
                                            ? `${x.mobile_images.image_url
                                            }?${Date.now()}`
                                            : ""
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Promo
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "promo_mobile",
                                          "AR2",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x.mobile_images.promo_image_url !== "" ? (
                                      <img
                                        src={
                                          x.mobile_images.promo_image_url
                                            ? `${x.mobile_images.promo_image_url
                                            }?${Date.now()}`
                                            : ""
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Fallback
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "fallback_mobile",
                                          "AR2",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x.mobile_images.fallback_image_url !==
                                      "" ? (
                                      <img
                                        src={
                                          x.mobile_images.fallback_image_url
                                            ? `${x.mobile_images
                                              .fallback_image_url
                                            }?${Date.now()}`
                                            : ""
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                              {/* ------ */}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography>
                                  Subscription (Tablet images,16:9)
                                </Typography>
                                <Box
                                  py={1}
                                  px={1}
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Main
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "Subscription_tablet",
                                          "AR1",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x?.tablet_images.image_url !== "" ? (
                                      <img
                                        src={
                                          x?.tablet_images.image_url !== ""
                                            ? `${x?.tablet_images.image_url
                                            }?${Date.now()}`
                                            : null
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Promo
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "promo_tablet",
                                          "AR1",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x.tablet_images.promo_image_url !== "" ? (
                                      <img
                                        src={
                                          x.tablet_images.promo_image_url !==
                                            ""
                                            ? `${x.tablet_images.promo_image_url
                                            }?${Date.now()}`
                                            : null
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Fallback
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "fallback_tablet",
                                          "AR1",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x.tablet_images.fallback_image_url !==
                                      "" ? (
                                      <img
                                        src={
                                          x.tablet_images.fallback_image_url
                                            ? `${x.tablet_images
                                              .fallback_image_url
                                            }?${Date.now()}`
                                            : ""
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                              {/* ------------ */}
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography>
                                  Subscription (Mobile Web images,9:14.2)
                                </Typography>
                                <Box
                                  py={1}
                                  px={1}
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Main
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "Subscription_mweb",
                                          "AR2",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x?.mweb_images.image_url !== "" ? (
                                      <img
                                        src={
                                          x?.mweb_images.image_url !== ""
                                            ? `${x?.mweb_images.image_url
                                            }?${Date.now()}`
                                            : null
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Promo
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "promo_mweb",
                                          "AR2",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x.mweb_images.promo_image_url !== "" ? (
                                      <img
                                        src={
                                          x.mweb_images.promo_image_url !==
                                            ""
                                            ? `${x.mweb_images.promo_image_url
                                            }?${Date.now()}`
                                            : null
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Fallback
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "fallback_mweb",
                                          "AR2",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x.mweb_images.fallback_image_url !==
                                      "" ? (
                                      <img
                                        src={
                                          x.mweb_images.fallback_image_url
                                            ? `${x.mweb_images
                                              .fallback_image_url
                                            }?${Date.now()}`
                                            : ""
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                              {/* ------ */}

                              <Box
                                py={1}
                                px={1}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography>LearnMore</Typography>
                                <Box
                                  sx={{ display: "flex", flexDirection: "row" }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Main
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "Subscription",
                                          "learnmore",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x.learn_more_image_url !== "" ? (
                                      <img
                                        src={
                                          x.learn_more_image_url
                                            ? `${x.learn_more_image_url
                                            }?${Date.now()}`
                                            : ""
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                      paddingLeft: "20px",
                                    }}
                                  >
                                    <Typography
                                      sx={{
                                        fontSize: "15px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Fallback
                                    </Typography>
                                    <Input
                                      type="file"
                                      inputProps={{ accept: "image/*" }}
                                      onChange={(event) =>
                                        handlePackageImage(
                                          event,
                                          "packages",
                                          "fallback",
                                          "learnmore",
                                          x.ovpSKU,
                                          i
                                        )
                                      }
                                    ></Input>
                                    {x.learn_more_fallback_image_url !== "" ? (
                                      <img
                                        src={
                                          x.learn_more_fallback_image_url
                                            ? `${x.learn_more_fallback_image_url
                                            }?${Date.now()}`
                                            : ""
                                        }
                                        alt="image"
                                        style={{
                                          height: "180px",
                                          width: "320px",
                                          paddingTop: "10px",
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                              <Box display="flex" justifyContent="end">
                                <Button
                                  variant="contained"
                                  color="info"
                                  onClick={() => handleSave(x, i)}
                                >
                                  Save
                                </Button>
                              </Box>
                            </CardContent>
                          </Collapse>
                        </Card>
                      </Box>
                    );
                  }
                )}
              </Box>
            </Card>
            <Grid style={{ marginTop: "25px", marginBottom: "25px", padding: "25px",border:"",backgroundColor: "transparent",boxShadow:"0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",borderRadius:"4px"}}>
              <Typography style={{ textAlign: "center" }}
                sx={{ fontSize: "1.25rem" }}
                fontWeight="500">Package Config</Typography>
              <Grid style={{ marginTop: "25px", marginBottom: "25px", paddingLeft: "0px" }}>
                <div style={{ marginTop: "20px" }}>
                  <div style={{ display: "flex", flexDirection: "column", marginBottom: "10px", width: "50%" }}>
                    <label htmlFor="mweb-subscription-tab-1-label" style={{ color: "black" }}>mWeb Subscription Tab Label TV</label>
                    <TextField
                      id="mweb-subscription-tab-1-label"
                      size="small"
                      sx={{ width: "50%" }}
                      onChange={(event) =>
                        handleChangemWebTabTitle(
                          event,
                          "mweb_subscription_tab_1_label"
                        )
                      }
                      value={config?.mweb_subscription_tab_1_label || ""}
                      variant="outlined"
                    />
                  </div>
                  <Typography style={{ marginTop: "25px" }}>
                    {config?.mweb_subscription_tab_1_label} ids
                  </Typography>
                  <FormControl style={{ width: "50%" ,backgroundColor:"transparent"}}>
                    <Select
                      className="dropdown"
                      placeholder="Select Option"
                      value={
                        ovpSkus?.filter((obj) =>
                          config?.mweb_subscription_tab_1_ids?.includes(
                            obj.ovpSKU
                          )
                        )}
                      options={getAvailableOptions(0)}
                      onChange={(e) => handleChange(0, e)}
                      isMulti
                      getOptionLabel={({ ovpSKU }) => `${ovpSKU}`}
                      getOptionValue={({ ovpSKU }) => ovpSKU}
                      styles={customStyles}
                    />
                  </FormControl>

                  <div className="hyper-link" style={{ display: "flex", flexDirection: "column", marginTop: "25px", width: "50%" }}>
                    <label htmlFor="mweb_subscription_tab_1_hyperlink" style={{ color: "black" }}>{`mWeb ${config?.mweb_subscription_tab_1_hyperlink} Label TV HyperLink`}</label>
                    <TextField
                      // label={`mWeb ${config?.mweb_subscription_tab_1_hyperlink} Label TV HyperLink`}
                      id="mweb_subscription_tab_1_hyperlink"
                      size="small"
                      sx={{ width: "50%", paddingBottom: "10px" }}
                      // onChange={handlePassword}
                      onChange={(event) =>
                        handleChangeHyperLink(
                          event,
                          "mweb_subscription_tab_1_hyperlink"
                        )
                      }
                      value={
                        config?.mweb_subscription_tab_1_hyperlink || ""
                      }
                      variant="outlined"
                    />
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", marginTop: "25px", width: "50%" }}>
                  <label htmlFor="mWeb Subscription Tab Label TV" style={{ color: "black" }}>mWeb Subscription Tab Label TV</label>
                  <TextField
                    // label="mWeb Subscription Tab Label TV"
                    id="mWeb Subscription Tab Label TV"
                    size="small"
                    sx={{ width: "50%", paddingBottom: "10px" }}
                    // onChange={handlePassword}
                    onChange={(event) =>
                      handleChangemWebTabTitle(
                        event,
                        "mweb_subscription_tab_2_label"
                      )
                    }
                    value={config?.mweb_subscription_tab_2_label || ""}
                    variant="outlined"
                  />
                  <Typography style={{ marginTop: "25px" }}>
                    {config?.mweb_subscription_tab_2_label} ids
                  </Typography>

                  <FormControl style={{ width: "" }}>
                    <Select
                      className="dropdown"
                      placeholder="Select Option"
                      value={ovpSkus?.filter((obj) =>
                        config?.mweb_subscription_tab_2_ids?.includes(
                          obj.ovpSKU
                        )
                      )}
                      options={getAvailableOptions(1)}
                      onChange={(e) => handleChange(1, e)}
                      isMulti
                      getOptionLabel={({ ovpSKU }) => `${ovpSKU}`}
                      getOptionValue={({ ovpSKU }) => ovpSKU}
                      styles={customStyles}
                    />
                  </FormControl>
                  <div className="hyper-link" style={{ display: "flex", flexDirection: "column", marginTop: "25px", width: "" }}>
                    <label htmlFor="mweb_subscription_tab_2_hyperlink" style={{ color: "black" }}>{`${config?.mweb_subscription_tab_2_hyperlink} TV HyperLink`}</label>
                    <TextField
                      // label={`${config?.mweb_subscription_tab_2_hyperlink} TV HyperLink`}
                      id="mweb_subscription_tab_2_hyperlink"
                      size="small"
                      notched={false}
                      sx={{ width: "50%", paddingBottom: "10px" }}
                      // onChange={handlePassword}
                      onChange={(event) =>
                        handleChangeHyperLink(
                          event,
                          "mweb_subscription_tab_2_hyperlink"
                        )
                      }
                      value={config?.mweb_subscription_tab_2_hyperlink || ""}
                      variant="outlined"
                    />
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", marginTop: "25px", width: "50%" }}>
                  <label htmlFor="mweb_subscription_tab_3_label" style={{ color: "black" }}>mWeb Subscription Tab Label TV</label>
                  <TextField
                    id="mweb_subscription_tab_3_label"
                    // label="mWeb Subscription Tab Label TV"
                    size="small"
                    sx={{ width: "50%", paddingBottom: "10px" }}
                    // onChange={handlePassword}
                    onChange={(event) =>
                      handleChangemWebTabTitle(
                        event,
                        "mweb_subscription_tab_3_label"
                      )
                    }
                    value={config?.mweb_subscription_tab_3_label || ""}
                    variant="outlined"
                  />
                  <Typography style={{ marginTop: "25px" }}>
                    {config?.mweb_subscription_tab_3_label} ids
                  </Typography>
                  <FormControl style={{ width: "" }}>
                    <Select
                      className="dropdown"
                      placeholder="Select Option"
                      value={ovpSkus?.filter((obj) =>
                        config?.mweb_subscription_tab_3_ids?.includes(
                          obj.ovpSKU
                        )
                      )}
                      options={getAvailableOptions(2)}
                      onChange={(e) => handleChange(2, e)}
                      isMulti
                      getOptionLabel={({ ovpSKU }) => `${ovpSKU}`}
                      getOptionValue={({ ovpSKU }) => ovpSKU}
                      styles={customStyles}
                    />
                  </FormControl>
                  <div className="hyper-link" style={{ display: "flex", flexDirection: "column", marginTop: "25px", width: "" }}>
                    <label htmlFor="mweb_subscription_tab_3_hyperlink" style={{ color: "black" }}>{`${config?.mweb_subscription_tab_3_hyperlink} HyperLink`}</label>

                    <TextField
                      id="mweb_subscription_tab_3_hyperlink"
                      // label={`${config?.mweb_subscription_tab_3_hyperlink} HyperLink`}
                      size="small"
                      sx={{ width: "50%", paddingBottom: "10px" }}
                      notched={false}
                      // InputLabelProps={{
                      //     shrink: false,
                      //   }}
                      // onChange={handlePassword}
                      onChange={(event) =>
                        handleChangeHyperLink(
                          event,
                          "mweb_subscription_tab_3_hyperlink"
                        )
                      }
                      value={config?.mweb_subscription_tab_3_hyperlink || ""}
                      variant="outlined"
                    />
                  </div>
                </div>

                <div style={{ display: "flex", flexDirection: "column", marginTop: "25px", width: "50%" }}>
                  <label htmlFor="mweb_subscription_tab_4_label" style={{ color: "black" }}>mWeb Subscription Tab Label TV</label>

                  <TextField
                    id="mweb_subscription_tab_4_label"
                    // label="mWeb Subscription Tab Label TV"
                    size="small"
                    sx={{ width: "50%", paddingBottom: "10px" }}
                    // onChange={handlePassword}
                    onChange={(event) =>
                      handleChangemWebTabTitle(
                        event,
                        "mweb_subscription_tab_4_label"
                      )
                    }
                    value={config?.mweb_subscription_tab_4_label || ""}
                    variant="outlined"
                  />
                  <Typography style={{ marginTop: "25px" }}>
                    {config?.mweb_subscription_tab_4_label} ids
                  </Typography>
                  <FormControl style={{ width: "" }}>
                    <Select
                      className="dropdown"
                      placeholder="Select Option"
                      value={ovpSkus?.filter((obj) =>
                        config?.mweb_subscription_tab_4_ids?.includes(
                          obj.ovpSKU
                        )
                      )}
                      options={getAvailableOptions(3)}
                      onChange={(e) => handleChange(3, e)}
                      isMulti
                      getOptionLabel={({ ovpSKU }) => `${ovpSKU}`}
                      getOptionValue={({ ovpSKU }) => ovpSKU}
                      styles={customStyles}
                    />
                  </FormControl>
                  <div className="hyper-link" style={{ display: "flex", flexDirection: "column", marginTop: "25px", width: "" }}>
                    <label htmlFor="mweb_subscription_tab_4_hyperlink" style={{ color: "black" }}>{`${config?.mweb_subscription_tab_4_hyperlink} hyperlink`}</label>
                    <TextField
                      id="mweb_subscription_tab_4_hyperlink"
                      // label={`${config?.mweb_subscription_tab_4_hyperlink} hyperlink`}
                      size="small"
                      sx={{ width: "50%", paddingBottom: "10px" }}
                      // onChange={handlePassword}
                      onChange={(event) =>
                        handleChangeHyperLink(
                          event,
                          "mweb_subscription_tab_4_hyperlink"
                        )
                      }
                      value={config?.mweb_subscription_tab_4_hyperlink || ""}
                      variant="outlined"
                    />
                  </div>
                </div>
              </Grid>
              {/* Baseplan */}
              <Grid style={{ marginTop: "25px", marginBottom: "25px", padding: "20px", paddingLeft: "0px", maxHeight: "500px",minHeight:"150px", overflow: "scroll" }} >
                {config?.basePlan?.map((field, index) => (
                  <div key={index}>
                    <Grid container spacing={2} alignItems="center" justifyContent={"center"} mb={2}>
                      <Grid item xs={4}>
                        <Typography>Base Plan</Typography>
                        <Select
                          value={field.basePlanOvp ? { ovpSKU: field.basePlanOvp } : null}
                          onChange={(selectedOption) => handleBaseplanChange(index, selectedOption?.ovpSKU)}
                          options={getAvailableBasePlanOptions(index)}
                          isClearable
                          placeholder="Select Baseplan"
                          getOptionLabel={({ ovpSKU }) => `${ovpSKU}`}
                          getOptionValue={({ ovpSKU }) => ovpSKU}
                        />
                      </Grid>
                      <Grid item xs>
                        <Typography>Durations Plan</Typography>
                        {console.log(ovpSkus, "ovpSkus")
                        }
                        <Select
                          isMulti
                          value={
                            ovpSkus?.filter((obj) =>
                              field?.durationPlanOvp?.includes(
                                obj.ovpSKU
                              ))}
                          onChange={(selectedOptions) => handleSelectChange(index, selectedOptions)}
                          options={getAvailableDurationPlanOptions(index)}
                          getOptionLabel={({ ovpSKU }) => `${ovpSKU}`}
                          getOptionValue={({ ovpSKU }) => ovpSKU}
                          isClearable
                        />
                      </Grid>
                      <Grid item >
                        {index === fields.length - 1 && (
                          <GreenCircleButton onClick={handleAddField} style={{ marginTop: "30px" }}>
                            <AddIcon />
                          </GreenCircleButton>
                        )}
                      </Grid>
                      <Grid item>
                        <RedDeleteButton onClick={() => handleRemoveField(index)} disabled={fields.length === 1} style={{ marginTop: "30px" }}>
                          <DeleteIcon />
                        </RedDeleteButton>
                      </Grid>
                    </Grid>
                  </div>
                ))}
              </Grid>
              <Box display="flex" justifyContent="end" m={2}>
                    <Button
                      variant="contained"
                      color="info"
                      onClick={handleUpdateBasePlanSave}
                      notched={false}
                    >
                      Save
                    </Button>
                  </Box>
            </Grid>
           
                  
            </Grid>
        </Grid>
        {/* Dailog newpCKges */}
        <Dialog open={openDialog} onClose={handleClose} 
        fullWidth={true}
        sx={{
          '& .MuiDialog-paper': {
            maxWidth: "1024px",
          },
        }}
        >
          <Box px={2} py={2}>
            <Typography
            sx={{ fontSize: "1.25rem" }}
            color="dark"
            fontWeight="400"
            >OVP SKU ID</Typography>
            <TextField
              sx={{ width: "50%" }}
              onChange={handleOvpSKU}
              onBlur={handleFocus}
              variant="outlined"
              size="small"
            />
            <FormHelperText sx={{ color: "red" }}>
              {error !== "" ? error : ""}
            </FormHelperText>
          </Box>
          <Box
            display="flex"
            gap={1}
            justifyContent="space-between"
            padding="16px"
            flexDirection="column"
            // color="#344767"
          >
            <>
              <Typography
                variant="caption"
                sx={{ fontSize: "1.25rem" }}
                // color="dark"
                fontWeight="400"
              >
                Plan Title
              </Typography>
              <TextField
                sx={{
                  // width: "40%",
                }}
                style={{width: "40%"}}
                size="small"
                onChange={(event) =>
                  handleCreatePlanTitle(event)
                }
                value={newPackage?.plan_title}
                variant="outlined"
              />
            </>
            <>
              <Typography
                variant="caption"
                sx={{ fontSize: "1.25rem" }}
                // color="dark"
                fontWeight="400"
              >
                Plan Description
              </Typography>
              <TextField
                sx={{
                  // width: "40%",
                }}
                style={{width: "40%"}}
                size="small"
                onChange={(event) =>
                  handleCreatePlanDescription(event)
                }
                value={newPackage?.plan_description}
                variant="outlined"
              />
            </>
          </Box>
          <Box px={2} >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>Subscription(web and tablet images,16:9)</Typography>
              <Box py={1} px={1} sx={{ display: "flex", flexDirection: "row" ,gap:"1rem"}}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Main
                  </Typography>
                  <TextField
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "Subscription_web_tab",
                        "AR1",
                        newPackage?.ovpSKU
                      )
                    }
                  />
                  {newPackage?.web_tab_images?.image_url ? (
                    <img
                      src={
                        newPackage?.web_tab_images?.image_url !== ""
                          ? `${newPackage?.web_tab_images?.image_url
                          }?${Date.now()}`
                          : null
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Promo
                  </Typography>
                  <TextField
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "promo_web_tab",
                        "AR1",
                        newPackage?.ovpSKU
                      )
                    }
                  />
                  {newPackage?.web_tab_images?.promo_image_url ? (
                    <img
                      src={
                        newPackage?.web_tab_images?.promo_image_url !== ""
                          ? `${newPackage?.web_tab_images?.promo_image_url
                          }?${Date.now()}`
                          : null
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Fallback
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "fallback_web_tab",
                        "AR1",
                        newPackage?.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.web_tab_images?.fallback_image_url ? (
                    <img
                      src={
                        newPackage?.web_tab_images?.fallback_image_url
                          ? `${newPackage?.web_tab_images?.fallback_image_url
                          }?${Date.now()}`
                          : ""
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              py={1}
              px={1}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography>Subscription(mobile images,9:14.2)</Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Main
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "Subscription_mobile",
                        "AR2",
                        newPackage?.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.mobile_images?.image_url ? (
                    <img
                      src={
                        newPackage?.mobile_images?.image_url
                          ? `${newPackage?.mobile_images?.image_url
                          }?${Date.now()}`
                          : ""
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Promo
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "promo_mobile",
                        "AR2",
                        newPackage?.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.mobile_images?.promo_image_url ? (
                    <img
                      src={
                        newPackage?.mobile_images?.promo_image_url
                          ? `${newPackage?.mobile_images?.promo_image_url
                          }?${Date.now()}`
                          : ""
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Fallback
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "fallback_mobile",
                        "AR2",
                        newPackage?.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.mobile_images?.fallback_image_url ? (
                    <img
                      src={
                        newPackage?.mobile_images?.fallback_image_url
                          ? `${newPackage?.mobile_images?.fallback_image_url
                          }?${Date.now()}`
                          : ""
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Box>

            {/* ------- */}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography>Subscription(Tablet images,16:9)</Typography>
              <Box py={1} px={1} sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Main
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "Subscription_tablet",
                        "AR1",
                        newPackage?.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.tablet_images?.image_url ? (
                    <img
                      src={
                        newPackage?.tablet_images?.image_url !== ""
                          ? `${newPackage?.tablet_images?.image_url
                          }?${Date.now()}`
                          : null
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Promo
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "promo_tablet",
                        "AR1",
                        newPackage?.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.tablet_images?.promo_image_url ? (
                    <img
                      src={
                        newPackage?.tablet_images?.promo_image_url !== ""
                          ? `${newPackage?.tablet_images?.promo_image_url
                          }?${Date.now()}`
                          : null
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Fallback
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "fallback_tablet",
                        "AR1",
                        newPackage?.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.tablet_images?.fallback_image_url ? (
                    <img
                      src={
                        newPackage?.tablet_images?.fallback_image_url
                          ? `${newPackage?.tablet_images?.fallback_image_url
                          }?${Date.now()}`
                          : ""
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Box>
            {/* ----- */}
            <Box
              py={1}
              px={1}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography>Subscription(Mobile Web images,9:14.2)</Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Main
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "Subscription_mweb",
                        "AR2",
                        newPackage?.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.mweb_images?.image_url ? (
                    <img
                      src={
                        newPackage?.mweb_images?.image_url
                          ? `${newPackage?.mweb_images?.image_url
                          }?${Date.now()}`
                          : ""
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Promo
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "promo_mweb",
                        "AR2",
                        newPackage?.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.mweb_images?.promo_image_url ? (
                    <img
                      src={
                        newPackage?.mweb_images?.promo_image_url
                          ? `${newPackage?.mweb_images?.promo_image_url
                          }?${Date.now()}`
                          : ""
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Fallback
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "fallback_mweb",
                        "AR2",
                        newPackage?.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.mweb_images?.fallback_image_url ? (
                    <img
                      src={
                        newPackage?.mweb_images?.fallback_image_url
                          ? `${newPackage?.mweb_images?.fallback_image_url
                          }?${Date.now()}`
                          : ""
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Box>
            {/* ------ */}
            <Box
              py={1}
              px={1}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography>LearnMore</Typography>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Learn More
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "Subscription",
                        "learnmore",
                        newPackage?.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.learn_more_image_url ? (
                    <img
                      src={
                        newPackage?.learn_more_image_url
                          ? `${newPackage?.learn_more_image_url}?${Date.now()}`
                          : ""
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: "20px",
                  }}
                >
                  <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                    Fallback
                  </Typography>
                  <Input
                    type="file"
                    inputProps={{ accept: "image/*" }}
                    disabled={error !== "" ? true : false}
                    onChange={(event) =>
                      handleNewPackageImage(
                        event,
                        "packages",
                        "fallback",
                        "learnmore",
                        newPackage.ovpSKU
                      )
                    }
                  ></Input>
                  {newPackage?.learn_more_fallback_image_url ? (
                    <img
                      src={
                        newPackage?.learn_more_fallback_image_url
                          ? `${newPackage?.learn_more_fallback_image_url
                          }?${Date.now()}`
                          : ""
                      }
                      alt="image"
                      style={{
                        height: "180px",
                        width: "320px",
                        paddingTop: "10px",
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            paddingBottom="30px"
            sx={{
              display: "flex",
              justifyContent: "end",
              paddingRight: "10px",
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              color="info"
              onClick={handleAddNewPackageUpdate}
            >
              Save
            </Button>
            <Button variant="contained" color="info" onClick={handleClose}>
              Cancel
            </Button>
          </Box>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={openSnackbar}
          onClose={handleSnackbarClose}
          message="Please enter all the values"
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (State) => {
  return {
    // BlinksUsers:state.blinksUsersReducers.BlinksUsers
    packageDetails: State.PackageReducer.allPackages,
    updateJson: State.PackageReducer.updateJson,
    projectDetails: State.ProjectReducer.projectDetails,
    authToken: State.loginReducer.authTokens,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllPackages: (data) => {
      dispatch(getAllPackages(data));
    },
    successfullyGetAllPackages: (data) => {
      dispatch(successfullyGetAllPackages(data));
    },
    updateJsonDetails: (data) => {
      dispatch(updateJsonDetails(data));
    },
    addNewJsonDetail: (data) => {
      dispatch(addNewJsonDetails(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Package);
