import React, { useState } from 'react';
import {
    Menu, MenuItem, Checkbox, Divider, FormControlLabel,
    Button, Switch, IconButton
} from '@mui/material';
import {
    ArrowDropDown as ArrowDropDownIcon,
    ArrowForwardIos as ArrowForwardIosIcon,
    Clear as ClearIcon
} from '@mui/icons-material';
import SocialIcon from "../../../assets/img/SignInTemplateAssets/socialIcon.svg";

const SocialMediaDropdown = ({ setTemplateObj, templateObj }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
    const [subMenus, setSubMenus] = useState({
        type: false,
        position: false,
        order: false,
        font: false,
    });

    // Ensure default values for settings and checkboxes
    const settings = templateObj.socialMedia?.settings || {
        type: 'Logo',
        position: 'Below Form',
        order: 'Vertical',
        fontFamily: 'Arial',
        fontColor: '#000000',
        buttonColor: '#3f51b5',
    };

    const checkboxes = templateObj.socialMedia?.checkboxes || {
        google: false,
        facebook: false,
    };

    const isSocialLoginEnabled = templateObj.socialMedia?.isSocialLoginEnabled ?? true;

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setIsMenuOpen(true);
    };

    const handleMenuClose = () => {
        setIsMenuOpen(false);
        setSubMenus({ type: false, position: false, order: false, font: false });
    };

    const handleToggle = () => {
        setTemplateObj((prev) => ({
            ...prev,
            socialMedia: {
                ...prev.socialMedia,
                isSocialLoginEnabled: !prev.socialMedia.isSocialLoginEnabled,
            },
        }));
    };

    const handleCheckboxChange = (name) => (event) => {
        setTemplateObj((prev) => ({
            ...prev,
            socialMedia: {
                ...prev.socialMedia,
                checkboxes: {
                    ...prev.socialMedia.checkboxes,
                    [name]: event.target.checked,
                },
            },
        }));
    };
    const handleSettingChange = (name, value) => {
        setTemplateObj((prev) => {
            const updatedTemplate = {
                ...prev,
                socialMedia: {
                    ...prev.socialMedia,
                    settings: {
                        ...prev.socialMedia.settings,
                        [name]: value,
                    },
                },
            };
            console.log(updatedTemplate); // Log to verify if state is updated correctly
            return updatedTemplate;
        });
        setSubMenus({ ...subMenus, [name]: false });
        setSubMenuAnchorEl(null);
    };

    const handleSubMenuToggle = (event, name) => {
        setSubMenuAnchorEl(event.currentTarget);
        setSubMenus({ ...subMenus, [name]: !subMenus[name] });
    };

    const renderSubMenu = (name, options) => (
        subMenus[name] && (
            <Menu
                anchorEl={subMenuAnchorEl}
                open={Boolean(subMenuAnchorEl) && subMenus[name]}
                onClose={() => setSubMenus({ ...subMenus, [name]: false })}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {options.map((option) => (
                    <MenuItem
                        key={option}
                        onClick={() => handleSettingChange(name, option)}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        )
    );

    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleMenuOpen}
                startIcon={<img src={SocialIcon} alt="form Icon" width={20} height={20} />}
                endIcon={<ArrowDropDownIcon />}
                sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px 16px',
                    whiteSpace: 'nowrap',
                    width: '100%',
                }}
            >
                Social Media
            </Button>

            <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
                <MenuItem>
                    <FormControlLabel
                        control={<Switch checked={isSocialLoginEnabled} onChange={handleToggle} />}
                        label="Social Media Login"
                        labelPlacement="start"
                    />
                </MenuItem>
                <Divider />

                {['type', 'position', 'order'].map((name) => (
                    <React.Fragment key={name}>
                        <MenuItem
                            onClick={(event) => handleSubMenuToggle(event, name)}
                            disabled={!isSocialLoginEnabled}
                        >
                            {`${name.charAt(0).toUpperCase() + name.slice(1)}: ${settings[name]}`}
                            <ArrowForwardIosIcon style={{ marginLeft: 'auto' }} />
                        </MenuItem>
                        {renderSubMenu(
                            name,
                            name === 'type'
                                ? ['Logo', 'Logo Name']
                                : name === 'position'
                                ? ['Below Form', 'Part of Form']
                                : ['Vertical', 'Horizontal']
                        )}
                    </React.Fragment>
                ))}

                <Divider />

                <MenuItem
    onClick={(event) => handleSubMenuToggle(event, 'fontFamily')}
    disabled={!isSocialLoginEnabled}
>
    {`Font: ${settings.fontFamily}`}
    <ArrowForwardIosIcon style={{ marginLeft: 'auto' }} />
</MenuItem>
                {renderSubMenu('fontFamily', ['Arial', 'Courier New', 'Times New Roman'])}
                {['Font', 'Button'].map((colorType) => (
                    <MenuItem key={colorType} disabled={!isSocialLoginEnabled}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{`${colorType} Color:`}</span>
                            <input
                                type="color"
                                value={settings[`${colorType.toLowerCase()}Color`]}
                                onChange={(e) =>
                                    handleSettingChange(`${colorType.toLowerCase()}Color`, e.target.value)
                                }
                                style={{
                                    marginLeft: '10px',
                                    width: '30px',
                                    height: '30px',
                                    borderRadius: '50%',
                                }}
                            />
                            <IconButton
                                onClick={() =>
                                    handleSettingChange(
                                        `${colorType.toLowerCase()}Color`,
                                        colorType === 'Font' ? '#000000' : '#3f51b5'
                                    )
                                }
                            >
                                <ClearIcon />
                            </IconButton>
                        </div>
                    </MenuItem>
                ))}
                <Divider />

                {['google', 'facebook'].map((name) => (
                    <MenuItem key={name} disabled={!isSocialLoginEnabled}>
                        <Checkbox
                            checked={checkboxes[name]}
                            onChange={handleCheckboxChange(name)}
                            color="primary"
                        />
                        {name.charAt(0).toUpperCase() + name.slice(1)}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};

export default SocialMediaDropdown;
