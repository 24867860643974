import React from "react";
import Slider from "react-slick";

const SimpleSlider = (templateObj) => {
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 768, 
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            dots: false,
          },
        },
        {
          breakpoint: 1024, 
          settings: {
            slidesToShow: 1, 
            slidesToScroll: 1,
            dots: false,
          },
        },
      ],
    };
  console.log(templateObj,templateObj?.templateObj?.media?.slideshow,"slider");
  
    return (
      <Slider {...settings}>
        {templateObj?.templateObj?.media?.slideshow?.map((image,i)=>(
            <div key={i}>
          <img src={image?.fileURL} alt={image?.fileName}  style={{  height: '300px', objectFit: 'cover' }} />
        </div>
        ))}
      
      </Slider>
    );
  };

  export default SimpleSlider