import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { connect } from "react-redux";

import TvIcon from "@mui/icons-material/Tv";
import DesktopWindowsIcon from "@mui/icons-material/DesktopWindows";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import StyledSelect from "./Components/SelectComponent";
import Dropdown from "./DropDown";
import CustomDropdown from "./DeviceDropDown";
import PreviewScreen from "./Preview";
import { ReactComponent as EditSvg } from "../../assets/img/SignInTemplateAssets/edit.svg";
import LiveIcon from "../../assets/img/SignInTemplateAssets/Live.svg";
import NotLiveIcon from "../../assets/img/SignInTemplateAssets/Not Live.svg";
import MediaDropdown from "./Components/MediaDropDown";
import SocialMediaDropdown from "./Components/SocialMediaDropDown";
import ImageColorDropdown from "./Components/ImageColorDropDown";
import { getAllTemplateData, getAllTemplateAction, createTemplateAction, updateTemplateAction, deleteTemplateAction } from "./actions/templateActions";
import AddIcon from '@mui/icons-material/Add';
import MobilePreviewScreen from "./Preview/MobilePreview";
import MultipleSelectWithIcons from "./Components/Platforms";
import DraftDropdown from "./Components/tempDraftDropDown";
import ConfirmationDialog from "./Components/Dialog"; 
import { getDefaultTemplate } from "./utils";
const LoginPage = (props) => {
  const [activeTab, setActiveTab] = useState("Tv");
  const [selectedType, setSelectedType] = useState("");
  const [isLogin, setIsLogin] = useState(false)
  const [formSettings, setFormSettings] = useState({
    position: "Right Side",
    fontSize: "20px",
    fontColor: "#000000",
    fieldColor: "#ffffff",
    buttonColor: "#0056d2",
    media: "",
    socialMedia: "",
    background: "",
  });
  const [templateObj, setTemplateObj] = useState({
    "templateName": "Login Screen",
    "platform": [],
    "media": {
      "file": "",
      "type": "",
      "slideshow": [],
      "fileURL": "",
      "position": "",
    },
    "background": {
      "imageUrl": "",
      "backgroundColor": "",
      "name": ""
    },
    "socialMedia": {
      "checkboxes": {
        "google": false,
        "facebook": false,
      },
      "settings": {
        "fontFamily": "Arial",
        "fontColor": "#000000",
        "buttonColor": "#3f51b5",
        "type": "Logo Name",
        "position": "Below Form",
        "order": "Horizontal"
      }
    },
    "form": {
      "type": "Email",
      "position": "Right",
      "fontSize": 16,
      "fontColor": "",
      "fieldColor": "",
      "buttonColor": "#3636dd",
      "currentFont": "Arial"
    },
    "status": "Draft",
    "draftName": "Initial Draft",
    "screensaver": "NO",
        "setting": "NO"
  }
  );
  const [isEditing, setIsEditing] = useState(false);
  const [tvLogin, setTvLogin] = useState("");
  const [tvItemSelected, setTvItemSelected] = useState({})
  const [dropdownItems, setDropdownItems] = useState([
    { label: "Live - Item 1", type: "live" },
  ]);
  // platforms
  const [draftLive, setDraftLive] = useState([])
  const [allTemplate, setAllTemplate] = useState([])


  useEffect(() => {
    const payload = {
      p_id: props?.projectDetails?.p_id,
      t_id: props?.authToken?.t_id
    }
    console.log(payload, "oottt");
    props.getAllTemplateAction(payload);
    setTemplateObj(getDefaultTemplate("Login Screen"));
  }, [props?.updateTemplate, props?.createTemplate, props.deleteTemplate])

  useEffect(() => {
    if (props.templateData) {
      console.log("[props.templateData");
      
      setAllTemplate(props?.templateData?.data);
      setDropdownItems(props?.templateData?.data);
    }
  }, [props.templateData]);
  // platform ------
  useEffect(() => {
    if (allTemplate && templateObj?.platform) {
      // const filteredData = allTemplate?.filter(item => {
      //   const itemPlatforms = item.platform || [];
      //   const templatePlatforms = templateObj?.platform || [];
  
        // Compare arrays for exact match
      //   return (
      //     itemPlatforms.length === templatePlatforms.length &&
      //     itemPlatforms.every(platform => templatePlatforms.includes(platform))
      //   );
      // });
      setDraftLive(allTemplate);
    }
  }, [templateObj?.platform,allTemplate]);



  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };



  const handleAddNewTemplate = () => {
    // setTemplateObj({
    //   "templateName": "Login Screen",
    //   "platform": [],
    //   "media": {
    //     "file": "",
    //     "type": "",
    //     "slideshow": [],
    //     "fileURL": "",
    //     "position": "",
    //   },
    //   "background": {
    //     "imageUrl": "",
    //     "backgroundColor": "",
    //     "name": ""
    //   },
    //   "socialMedia": {
    //     "checkboxes": {
    //       "google": false,
    //       "facebook": false,
    //       "whatsapp": false
    //     },
    //     "settings": {
    //       "fontFamily": "Arial",
    //       "fontColor": "#000000",
    //       "buttonColor": "#3f51b5",
    //       "type": "Logo Name",
    //       "position": "Part of Form",
    //       "order": "Horizontal"
    //     }
    //   },
    //   "form": {
    //     "type": "Email",
    //     "position": "Right",
    //     "fontSize": 16,
    //     "fontColor": "#000000",
    //     "fieldColor": "",
    //     "buttonColor": "",
    //     "currentFont": "Arial"
    //   },
    //   "status": "Draft",
    //   "draftName": "Initial Draft"
    // })
    setTemplateObj(getDefaultTemplate("Login Screen"));
    setActiveTab("Tv");
  }
  const [itemToDelete, setItemToDelete] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const handleDelete = (data) => {
    setItemToDelete(data); // Set the item to delete
    setOpenDeleteDialog(true); // Open the dialog
  };

  const handleDeleteDialogClose = () => {
    setItemToDelete(null); // Reset the item
    setOpenDeleteDialog(false); // Close the dialog
  };
  const handleDeleteConfirm = () => {
    const payload = {
      p_id: props?.projectDetails?.p_id,
      _id: itemToDelete?._id,
    };
    console.log(itemToDelete, "handle delete");
    setDropdownItems((prevItems) =>
      prevItems.filter((item) => item.draftName !== itemToDelete?.draftName)
    );
    props.deleteTemplateAction(payload);
    handleDeleteDialogClose(); // Close the dialog after deletion
  };

  const handleSave = () => {
    setDropdownItems((prevItems) =>
      prevItems.map((item) =>
        item.draftName === tvItemSelected?.draftName
          ? { ...item, draftName: templateObj?.draftName }
          : item
      )
    );
    setTvItemSelected({ ...tvItemSelected, templateName: templateObj?.draftName });
    setIsEditing(false);
  };

  const handleSaveAsDraft = () => {
    const updatedTemplateObj = { ...templateObj, status: "Draft" };
    setTemplateObj(updatedTemplateObj);
    const payload = {
      templateObj: updatedTemplateObj,
      ids: { p_id: props?.projectDetails?.p_id, t_id: props?.authToken?.t_id },
    };

    console.log(payload, "Saving as Draft");
    props.createTemplateAction(payload);
  };

  const handlePublish = () => {
    const updatedTemplateObj = { ...templateObj, status: "Live" };
    setTemplateObj(updatedTemplateObj);
    const payload = {
      templateObj: updatedTemplateObj,
      ids: { p_id: props?.projectDetails?.p_id, t_id: props?.authToken?.t_id },
    };
    props.updateTemplateAction(payload);
  }

  const handleTVSelect = (data) => {
    console.log(data, "handleTVSelect")
    setTvItemSelected(data);
    setTvLogin(data?.draftName);
    setTemplateObj(data)
  }
  const handleChangeTemplateName = (e) => {
    setTemplateObj((prevState) => ({
      ...prevState,
      draftName: e.target.value,
    }));
  }
  const handleChangeTemplate = (template) => {

    // setTemplateObj((prevState) => ({
    //   ...prevState,
    //   templateName: template === 'login' ? "Login Screen" : "Signup Screen",
    // }));
    const type = template === "login" ? "Login Screen" : "Signup Screen";
    setTemplateObj(getDefaultTemplate(type));
  };

  console.log(props.updateTemplate,props.createTemplate, "templateData")
  console.log(templateObj, "act");
  console.log(draftLive, "draftLive");
  console.log(props.deleteTemplate, "deleteTemplate");

  return (
    <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "12px",
            bgcolor: "#E9EAFF",
            padding: "5px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
          }}
        >
          <Button
            variant=""
            sx={{
              bgcolor: templateObj?.templateName === "Login Screen" ? "#CDDEFF" : "#DDDDDD",
              mx: 1,
              borderRadius: "10px",
              fontSize: "12px",
            }}
            onClick={() => handleChangeTemplate('login')}
          >
            Login
          </Button>
          <Button
            variant=""
            sx={{
              bgcolor: templateObj?.templateName === "Signup Screen" ? "#CDDEFF" : "#DDDDDD",
              borderRadius: "10px",
              fontSize: "12px",
            }}
            onClick={() => handleChangeTemplate('signup')}
          >
            Signup
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          bgcolor: "#E9EAFF",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            p: 2,
            bgcolor: "#f0f0f0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {/* Tabs */}
          <Box >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{ textTransform: 'none' }}
              onClick={handleAddNewTemplate}
            >Add</Button>
          </Box>
          <Box sx={{ display: "flex" }}>
            <MultipleSelectWithIcons
              setTemplateObj={setTemplateObj}
              templateObj={templateObj}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            {/* <Box>
              <CustomDropdown
                items={dropdownItems}
                onDelete={handleDelete}
                title={"TV"}
                type={"Tv"}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                handleTVSelect={handleTVSelect}
                selectedTvItem={tvItemSelected}
                templateObj={templateObj}
              />
            </Box>
            <Box>
              <CustomDropdown
                items={dropdownItems}
                onDelete={handleDelete}
                title={"Desktop, Web, Tablet"}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                type={"Desktop"}
                handleTVSelect={handleTVSelect}
                selectedTvItem={tvItemSelected}
                templateObj={templateObj}
              />
            </Box>
            <Box>
              <CustomDropdown
                items={dropdownItems}
                onDelete={handleDelete}
                title={"Mobile"}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                type={"Mobile"}
                handleTVSelect={handleTVSelect}
                selectedTvItem={tvItemSelected}
                templateObj={templateObj}
              />
            </Box> */}
            <Box>
              <DraftDropdown
              items={draftLive}
              onDelete={handleDelete}
              title={"Templates"}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              type={"Mobile"}
              handleTVSelect={handleTVSelect}
              selectedTvItem={tvItemSelected}
              templateObj={templateObj}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            {/* Editable TV Login Field */}
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {isEditing ? (
                <>
                  <TextField
                    value={templateObj?.draftName}
                    onChange={(e) => handleChangeTemplateName(e)}
                    variant="outlined"
                    size="small"
                    sx={{
                      fontSize: "12px",
                      bgcolor: "#CDDEFF",
                      mr: 1,
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "4px",
                      },
                    }}
                  />
                  <Button
                    onClick={handleSave}
                    variant="contained"
                    size="small"
                    sx={{ fontSize: "12px" }}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => setIsEditing(true)} // Enable editing mode
                    sx={{ minWidth: "24px", padding: 0 }}
                  >
                    <EditSvg style={{ width: "16px", height: "16px" }} />
                  </Button>
                  <Button
                    onClick={() => setIsEditing(true)} // Enable editing mode
                    variant="text"
                    sx={{
                      fontWeight: "normal",
                      color: "#333",
                      fontSize: "12px",
                    }}
                  >
                    {templateObj?.draftName}
                  </Button>
                </>
              )}
            </Box>

            {/* Status Button with Dynamic Icon */}
            <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
              <Typography
                variant="body2"
                sx={{ fontSize: "12px", color: "#333" }}
              >
                Status
              </Typography>
              {templateObj?.status === "Draft" ? <img
                src={NotLiveIcon}
                alt="Status Icon"
                style={{ width: "25px", height: "25px", marginLeft: "5px" }}
              /> : <img
                src={LiveIcon}
                alt="Status Icon"
                style={{ width: "25px", height: "25px", marginLeft: "5px" }}
              />}
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: 2 }}>
            <Button
              onClick={() => handleSaveAsDraft()}
              variant="text"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "#333",
                bgcolor: "#979797",
                fontSize: "12px",
                borderRadius: "10px",
              }}
            >
              Save as Draft
            </Button>

            <Button
              onClick={() => handlePublish()}
              variant="text"
              sx={{
                display: "flex",
                alignItems: "center",
                fontWeight: "bold",
                color: "#fff",
                bgcolor: "#3F96EF",
                fontSize: "12px",
                borderRadius: "10px",
              }}
            >
              Publish Changes
            </Button>
          </Box>
        </Box>

        {/* Main Content Section */}
        <Grid
          container
          sx={{ flexGrow: 1, bgcolor: "#E9EAFF", height: "100vh", marginTop: "5px" }}
        >
          {templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) ? <MobilePreviewScreen
            background="path/to/background-image.jpg"
            logoSrc="path/to/logo-image.png"
            logoPosition="left"
            formPosition="center"
            componentType="logo"
            fontSize="1.2rem"
            fontColor="#333"
            buttonColor="#205AC8"
            buttonTextColor="#ffffff"
            templateObj={templateObj}
          /> : <PreviewScreen
            background="path/to/background-image.jpg"
            logoSrc="path/to/logo-image.png"
            logoPosition="left"
            formPosition="center"
            componentType="logo"
            fontSize="1.2rem"
            fontColor="#333"
            buttonColor="#205AC8"
            buttonTextColor="#ffffff"
            templateObj={templateObj}
          />}
        </Grid>

        {/* Footer (Settings) */}
        <Box sx={{ p: 2, bgcolor: "#f0f0f0" }}>
          <Grid container spacing={1}>
            <Grid item container xs={12} md={10} spacing={1}>
              <Grid item xs={6} md={2}>
                <Dropdown setTemplateObj={setTemplateObj} templateObj={templateObj} activeTab={activeTab} />
              </Grid>

              <Grid item xs={6} md={2}>
                <MediaDropdown setTemplateObj={setTemplateObj} templateObj={templateObj} activeTab={activeTab} />
              </Grid>
              <Grid item xs={6} md={2}>
                <SocialMediaDropdown setTemplateObj={setTemplateObj} templateObj={templateObj} />
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <ImageColorDropdown setTemplateObj={setTemplateObj} templateObj={templateObj} />
            </Grid>
          </Grid>
        </Box>

      </Box>
      {/* Use the ConfirmationDialog Component */}
      <ConfirmationDialog
        open={openDeleteDialog}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDeleteConfirm}
      />
    </Box>
  );
};

const mapStateToProps = (State) => {
  return {
    templateData: State.TemplateReducer.templateData,
    createTemplate: State.TemplateReducer.createTemplate,
    updateTemplate: State.TemplateReducer.updateTemplate,
    projectDetails: State.ProjectReducer.projectDetails,
    authToken: State.loginReducer.authTokens,
    deleteTemplate: State.TemplateReducer.deleteTemplate,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllTemplateAction: (data) => {
      dispatch(getAllTemplateAction(data));
    },
    createTemplateAction: (data) => {
      dispatch(createTemplateAction(data));
    },
    updateTemplateAction: (data) => {
      dispatch(updateTemplateAction(data));
    },
    deleteTemplateAction: (data) => {
      dispatch(deleteTemplateAction(data));
    }


  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
