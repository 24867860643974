import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import GoogleIcon from "../../../assets/img/SignInTemplateAssets/google.svg";

const GoogleElement = ({ text="Sign in with Google", bgColor="",templateObj }) => {
  const [background, setBackground] = useState(bgColor || '#ffffff'); // Default background
  const order = templateObj?.socialMedia?.settings?.order ;
  return (
    <Box 
    sx={{ display: 'flex',
        width: order === "Vertical" ? "285px" : undefined,
        alignItems: 'center',
        padding: order === "Vertical" ? '10px 20px' : '5px',
        borderRadius: '20px',
        color:  templateObj?.socialMedia?.settings?.fontColor || "black",
        fontSize: order === "Vertical"?'16px':"14px",
        cursor: 'pointer',
        backgroundColor: templateObj?.socialMedia?.settings?.buttonColor || "white",
        transition: 'background-color 0.3s ease',
        justifyContent:"center"
    }}
    >
      <img 
        src={GoogleIcon}
        alt="Google Icon" 
        width={24} 
        height={24} 
      />
      {templateObj?.socialMedia?.settings?.type === "Logo Name" &&<Typography 
        variant="body1" 
        sx={{ 
          marginLeft: '6px', 
          fontSize: '13px',  
          fontFamily: templateObj?.socialMedia?.settings?.fontFamily || "sans-serif"   
        }}
      >
        {text}
      </Typography>}
    </Box>
  );
};

export default GoogleElement;
