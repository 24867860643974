import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";
import {
    getAllTemplateAction,
    getAllTemplateData,
    createTemplateAction,
    createTemplateData,
    updateTemplateAction,
    successfullyUpdatedTemplateDetails,
    successfullydeleteTemplateDetails
} from "../actions/templateActions";

var p_id;

export function* watchCreateNewTemplate() {
  yield takeLatest("CREATE_TEMPLATE_ACTION", handleCreateTemplateDetails);
  yield takeLatest("UPDATE_TEMPLATE_ACTION", handleUpdateTemplateDetails);
  yield takeLatest("GET_ALL_TEMPLATE_ACTION", getAllTemplateDataAction);
  yield takeLatest("DELETE_TEMPLATE_ACTION", handleDeleteTemplate);
}

export const getAllTemplateDataApi = async (payload) => {
    console.log(payload.payload,"getAllTemplateDataApi");
    
    try {
      const URL=`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/screenTemplate?p_id=${payload?.payload?.p_id}`;
      const res = await axios.get(URL,{headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      }});
      console.log(res,"res getAllTemplateDataApi")
      return res.data;
    } catch (err) {
      console.log(err);
    }
  };
  function* getAllTemplateDataAction(payload) {
    console.log(payload,"getAllTemplateDataAction")
    const allViewJsonData = yield call(getAllTemplateDataApi,payload);

    yield put(getAllTemplateData(allViewJsonData));
  }

export const handleCreateTemplateApi = async (payload) => {
    console.log(payload,"handleCreateTemplateApi");
    const options = {
      method: "POST",
      url:`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/screenTemplate/add?p_id=${payload?.payload?.ids?.p_id}`,
      data:payload?.payload?.templateObj,
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
    };
    const res = await axios.request(options);
    return res.data;
  };
  
  function* handleCreateTemplateDetails(payload){
    console.log(payload,"handleUpdateConfigDetails payload");
    const res = yield call(handleCreateTemplateApi,payload)
    console.log(res,"updateConfigDetails")
    yield put(createTemplateData(res));
  }

export const handleUpdateTemplateDetailsApi = async (payload) => {
  console.log(payload,"cred");
  
  const options = {
    method: "POST",
    url:`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/screenTemplate/add?p_id=${payload?.payload?.ids?.p_id}`,
      data:payload?.payload?.templateObj,
      headers: {
        "x-api-key": process.env.REACT_APP_X_API_KEY,
        Authorization: localStorage.getItem("token"),
      },
  };
  const res = await axios.request(options);
  return res.data;
};

function* handleUpdateTemplateDetails(payload){
  console.log(payload,"handleUpdateConfigDetails payload");
  const res = yield call(handleUpdateTemplateDetailsApi,payload)
  console.log(res,"updateConfigDetails")
  yield put(successfullyUpdatedTemplateDetails(res));
}


export const handleDeleteTemplateApi = async (payload) => {
  console.log(payload,"cred");
  
  const options = {
    method: "DELETE",
    url:`${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/screenTemplate/${payload?.payload?._id}?p_id=${payload?.payload?.p_id}`,
    data: payload?.payload?.data,
    headers: {
    "x-api-key":process.env.REACT_APP_X_API_KEY,
      Authorization: localStorage.getItem("token"),
    },
  };
  const res = await axios.request(options);
  return res.data;
};

function* handleDeleteTemplate(payload){
  console.log(payload,"handleUpdateConfigDetails payload");
  const res = yield call(handleDeleteTemplateApi,payload)
  console.log(res,"updateConfigDetails")
  yield put(successfullydeleteTemplateDetails(res));
}





