import React, { useState } from 'react';
import { Button, Menu, MenuItem, Divider, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TvIcon from '@mui/icons-material/Tv';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; // Example icon

const DraftDropdown = ({ items, onDelete, title, activeTab, setActiveTab, type, handleTVSelect, selectedTvItem, templateObj }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        // setActiveTab(type)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const filteredItems = items
        ?.filter(item =>
            (templateObj?.templateName === "Signup Screen" && item?.templateName === "Signup Screen") ||
            (templateObj?.templateName === "Login Screen" && item?.templateName === "Login Screen")
        )
    const liveItems = filteredItems?.filter(item => item.status === "Live") || [];
    const draftItems = filteredItems?.filter(item => item.status === "Draft") || [];

    console.log(items, activeTab, filteredItems, "xyz")
    return (
        <div>
            <Button variant="text" sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: selectedItem !==null  ? 'bold' : 'normal',
                color: selectedItem !==null ? '#3467C6' : '#333',
                bgcolor: selectedItem !==null ? '#CDDEFF' : '',
                fontSize: "12px"
            }} onClick={handleClick}
             endIcon={<ArrowDropDownIcon />}>
                <TvIcon sx={{ mr: 1 }} />
                {title}
            </Button>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {liveItems?.length > 0 && (
                    <>
                        <Divider textAlign="center">Live</Divider>
                        {liveItems?.map((item,index) => (
                            <MenuItem key={index}
                                onClick={() => {
                                    handleTVSelect(item);
                                    setSelectedItem(index);
                                    setAnchorEl(null);
                                }}
                                sx={{
                                    backgroundColor: selectedItem === index ? '#CDDEFF' : 'transparent',
                                    fontWeight: selectedItem === index ? 'bold' : 'normal',
                                }}
                            >
                              <Typography
                                    variant="body1"
                                    sx={{
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        flex: 1, 
                                        marginRight: 1, 
                                    }}
                                >
                                    {item.draftName}
                                </Typography>
                                <IconButton
                                    edge="end"
                                    color="secondary"
                                    onClick={() => onDelete(item)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </MenuItem>
                        ))}
                    </>
                )}

                {/* Render Draft Items */}
                {draftItems?.length > 0 && (
                    <>
                        <Divider textAlign="center">Draft</Divider>
                        {draftItems?.map(item => (
                            <MenuItem key={item._id}
                                onClick={() => {
                                    handleTVSelect(item);
                                    setSelectedItem(item.draftName);
                                    setAnchorEl(null);
                                }}
                                sx={{
                                    backgroundColor: selectedItem === item.draftName ? '#CDDEFF' : 'transparent',
                                    fontWeight: selectedItem === item.draftName ? 'bold' : 'normal',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        flex: 1,
                                        marginRight: 1,
                                    }}
                                >
                                    {item.draftName}
                                </Typography>
                                <IconButton
                                    edge="end"
                                    color="secondary"
                                    onClick={() => onDelete(item)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </MenuItem>
                        ))}
                    </>
                )}
                {liveItems?.length === 0 && draftItems?.length === 0 && (
                    <MenuItem disabled>
                        <Typography variant="body2" sx={{ color: '#888' }}>
                            No results available
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
        </div>

    );
};

export default DraftDropdown;
