import { Box, Button, makeStyles, Tab, Tabs, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'

import KalturaExplorer from './KalthraExplorer';
import AssestExplorer from './AssestsExplorer';
import Snackbar from "components/Snackbar/CustomSnackbar";

import { getCategoryTreeVisualizationAction ,clearCacheAction} from '../actions';
import { getProjectByIdAction, } from "screens/WidgetManager/actions";

import Styles from '../../../assets/jss/material-dashboard-react/views/contentExplorerStyle';
import { connect } from 'react-redux';
import ContentExplorerIcon from "assets/img/sideNavIcons/ContentExplorer.svg"

const useStyle  = makeStyles(Styles);

const ContactExplorer =(props)=>{
  const [value,setValue] = useState(0)
  const [time, setTime] = useState(300);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let timerInterval;

    if (isRunning && time > 0) {
      timerInterval = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }

    if (time === 0) {
      clearInterval(timerInterval);
      setIsRunning(false); 
      setTime(300)
    }

    return () => clearInterval(timerInterval); 
  }, [isRunning, time]);

  const classes = useStyle();

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const startTimer = () => {
    const startTime = Date.now(); 
    localStorage.setItem("VIV_CMS_CACHE", startTime);
    setIsRunning(true);
  };


  useEffect(()=>{
    const storedTime = localStorage.getItem("VIV_CMS_CACHE");

    if (storedTime) {
      const startTime = parseInt(storedTime, 10); 
      const currentTime = Date.now();
      const elapsedTime = Math.floor((currentTime - startTime) / 1000); 

      if (elapsedTime < 300) {
        setTime(300 - elapsedTime);
        setIsRunning(true);
      } else {
        localStorage.removeItem("VIV_CMS_CACHE");
      }
    }
    props.getCategoryTreeVisualizationAction(props.projectDetails.p_id);
    props.getProjectByIdAction(props.projectDetails.p_id)
  },[]);


  const handleChange = (event,value)=>{
    setValue(value)
  }

  const refreshCacheClick=async()=>{
    startTimer()
    const result= await props.clearCacheAction(props?.projectDetails?.p_id)
  }

  return(
    <Box>
      <Box>
         <Box display="flex">
         <img src={ContentExplorerIcon} alt="Your Icon" style={{ width: '33px', height: '33px', marginRight: '8px', verticalAlign: 'middle' }} />
           <Typography className={classes.heading}>Content Explorer</Typography>
         </Box>
         <Box paddingTop='16px'>
          <Box style={{display:"flex", justifyContent:"space-between"}}>
          <Tabs value={value} TabIndicatorProps={{ style: { background: "#0192D4",height:'3px' } }} style={{ alignSelf:"flex-start"}} onChange={handleChange}>
              <Tab value={0} className={classes.unSelectedTab} classes={{selected:classes.tab}} label='Content explorer'/>
              <Tab value={1} className={classes.unSelectedTab} classes={{selected:classes.tab}} label='CMS Visualization'/>
           </Tabs>
           <Box style={{display:"flex" , flexDirection:"column", gap:"2px"}}>
              <Button className={classes.refreshButton}  disabled={isRunning} style={{background: !isRunning ?'linear-gradient(135deg, #339FED 0%, #885CF7 65.94%)':"#aca29f", alignSelf:"flex-start"}} onClick={refreshCacheClick}>  <Typography className={classes.ButtonText} >Refresh Cache</Typography></Button>
             <Typography className={classes.tab} style={{    width: '208px', fontSize:"14px", height:"10px", marginLeft:"8px"}} >  {isRunning &&`CoolDown Time ${formatTime(time)}`}</Typography>
            </Box>
          </Box>

            {value == 0 ? <AssestExplorer/> : ""}
            {value == 1 ? <KalturaExplorer data={props?.treeData}
                                           imageRatio={props.projectAspectRatio}
            />:""}
         </Box>
      </Box>
   { /*  <Snackbar
                open={cacheRefreshed}
                severity={
                  // props.createScreen?.data?.acknowledged
                  //   ? "warning"
                  //   : props.createScreen?.hzStatus === 200
                  //   ? "success"
                     "error"
                }
                message={
                "Messge"
                }
               setOpen={()=>{setCacheRefreshCache(false)}}
              />*/}
    </Box>
  )
}

const mapStateToProps = (State)=>{
  return {
      treeData:State.ContentExplorerReducer?.allCategories,
      projectDetails:State.ProjectReducer.projectDetails,
      projectAspectRatio:State.PlayListReducer.projectAspectRatio,
      visualizationErrorMessage:State.ContentExplorerReducer.visualizationErrorMessage
  }
}

const mapDispatchToProps = (dispatch)=>{
  return{
      getCategoryTreeVisualizationAction:(data)=>{
          dispatch(getCategoryTreeVisualizationAction(data));
      },
      getProjectByIdAction:(projectId)=>{
        dispatch(getProjectByIdAction(projectId))
      },
      clearCacheAction:(pid)=>{
        dispatch(clearCacheAction(pid))
      }
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(ContactExplorer)