import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import ListItemIcon from '@mui/material/ListItemIcon';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import TabletMacIcon from '@mui/icons-material/TabletMac';
import TvIcon from '@mui/icons-material/Tv'; 
import ComputerIcon from '@mui/icons-material/Computer'; 
import DevicesIcon from '@mui/icons-material/Devices';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    //   width: 250,
    },
  },
};

const platformIcons = {
  WEB_DES: <ComputerIcon />,
  WEB_MOB: <ComputerIcon />,
  AND_MOB: <PhoneAndroidIcon />,
  AND_TAB: <TabletMacIcon />,
  IOS_MOB: <PhoneAndroidIcon />,
  IOS_TAB: <TabletMacIcon />,
  AND_TV: <TvIcon />,
  SAMSUNG_SMART_TV: <TvIcon />,
  LG_SMART_TV: <TvIcon />,
  APPLE_TV: <TvIcon />,
  VIDAA_TV: <TvIcon />,
  DONGLE: <DevicesIcon />,
  FIRESTICK: <DevicesIcon />,
  STB: <DevicesIcon />,
  SKYGLASS_TV: <TvIcon />,
};

const platformsVal = [
  'WEB_DES', 'WEB_MOB', 'AND_MOB', 'AND_TAB', 'IOS_MOB', 'IOS_TAB', 
  'AND_TV', 'SAMSUNG_SMART_TV', 'LG_SMART_TV', 'APPLE_TV', 'VIDAA_TV', 
  'DONGLE', 'FIRESTICK', 'STB', 'SKYGLASS_TV'
];

export default function MultipleSelectWithIcons({templateObj,setTemplateObj}) {
  const [selectedPlatforms, setSelectedPlatforms] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setTemplateObj((prevTemplateObj) => {
      const newValue = Array.isArray(value) ? value : [value];
      return {
        ...prevTemplateObj,
        platform: newValue, // Update with the new flat list of selected items
      };
    });
  };

  const isMOBSelected = templateObj?.platform?.some(platform => platform.includes("MOB"));
  const isNonMOBSelected = templateObj?.platform?.some(platform => !platform.includes("MOB"));
console.log(templateObj.platform,"selectedPlatforms");

  return (
    <div>
      <FormControl sx={{ mr: 1, width: 170 }}>
        {/* <InputLabel id="platform-select-label"  sx={{ 
            textAlign: 'center', // Center the label
            width: '100%' // Ensure it takes full width for proper centering
          }}
        >Select Platforms</InputLabel> */}
        <Select
          value={templateObj?.platform}
          onChange={handleChange}
          multiple
          displayEmpty 
          renderValue={(selected) => selected.length === 0 ? "Select Platforms" : selected.join(', ')}
          MenuProps={MenuProps}
          sx={{
            fontSize: '0.875rem',
            height: 35,
          }}
          variant="outlined"
        >
            {templateObj?.platform?.length === 0 && (
            <MenuItem value="" disabled>
              Select Platforms
            </MenuItem>
          )}
          {platformsVal.map((platform) => (
            <MenuItem 
              key={platform} 
              value={platform} 
              disabled={
                (isMOBSelected && !platform.includes("MOB")) || 
                (isNonMOBSelected && platform.includes("MOB"))
              }
            >
              <ListItemIcon>
                {platformIcons[platform]}
              </ListItemIcon>
              <Checkbox checked={templateObj?.platform?.indexOf(platform) > -1} />
              <ListItemText primary={platform} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
