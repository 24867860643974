export const getDefaultTemplate = (type = "Login Screen") => ({
    templateName: type,
    platform: [],
    media: {
      file: "",
      type: "",
      slideshow: [],
      fileURL: "",
      position: "",
    },
    background: {
      imageUrl: "",
      backgroundColor: "",
      name: "",
    },
    socialMedia: {
      checkboxes: {
        google: false,
        facebook: false,
        whatsapp: false,
      },
      settings: {
        fontFamily: "Arial",
        fontColor: "#000000",
        buttonColor: "#3f51b5",
        type: "Logo Name",
        position: "Below Form",
        order: "Horizontal",
      },
    },
    form: {
      type: "Email",
      position: "Right",
      fontSize: 16,
      fontColor: "#000000",
      fieldColor: "",
      buttonColor: "#3636dd",
      currentFont: "Arial",
    },
    status: "Draft",
    draftName: "Initial Draft",
    screensaver: "NO",
        setting: "NO"
  });
  