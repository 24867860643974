import React, { useEffect, useState } from 'react';
import {
    Button,
    Menu,
    MenuItem,
    Divider,
    ListItemText,
    Input,
    Alert,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'; 
import ClearIcon from '@mui/icons-material/Clear';
import ImageIcon from '@mui/icons-material/Image'; 
import VideoCameraIcon from '@mui/icons-material/Camera';
import MediaIcon from "../../../assets/img/SignInTemplateAssets/mediaSelect.svg"

import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary'; 
import { imageHandlerUtil } from 'utils/imageHandlerUtil';

const MediaDropdown = ({setTemplateObj,templateObj,activeTab}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentMedia, setCurrentMedia] = useState({
        file: '',
        type: '', 
        slideshow: [],
        position:'',
        fileURL:'',
    });
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null); 
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedSlideshow, setSelectedSlideshow] = useState([]); 
    const [isPositionSubMenuOpen, setIsPositionSubMenuOpen] = useState(false);
    const [positionAnchorEl, setPositionAnchorEl] = useState(null);

    useEffect(() => {
        if (templateObj?.media) {
            setCurrentMedia(templateObj.media);
            setSelectedFile({
                fileName: templateObj?.media?.file || "",
                 fileURL: templateObj?.media?.fileURL || ""
            });
            setSelectedSlideshow( templateObj?.media?.slideshow || [])
        }
    }, [templateObj]);

    const updateTemplateMedia = (updatedMedia) => {
        console.log(updatedMedia,"updatedMedia");
        
        setTemplateObj((prevTemplateObj) => ({
            ...prevTemplateObj,
            media: updatedMedia,
        }));
    };

    // Open the main menu
    const handleMainMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    // Close the main menu
    const handleMainMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSlideshowChange = async (event) => {
        console.log(event,"handleFileChange");
        
        const _brandingLogoSupportedImageTypes = ["image/png"];
        const uploadAPIRoute = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-file`;
        const brandingUploadAPIRoute = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-content-badge`;
        const supportedImageTypes = ["image/jpeg", "image/png"];
        const fileName = event.target.files[0]?.name;
        await imageHandlerUtil({
            event,
            supportedImageTypes,
            maxSize: 1048576 * 5, // Max size 5MB
            uploadAPIRoute: uploadAPIRoute,
            handleImageInputError: (id, message) => {
                console.log(`Error for ${id}: ${message}`);
            },
            handleUploadSuccess: (id, imageUrl) => {
                const updatedSlideshow = [
                    ...(templateObj?.media?.slideshow || []), 
                    {
                        fileName: fileName,
                        fileURL: imageUrl, 
                    },
                ];
                const updatedMedia = {
                    ...currentMedia,
                    type: 'slideshow',
                    slideshow: updatedSlideshow,
                    fileURL: "",
                    file: ""
                };
    
                // Update state
                setSelectedFile({ fileName: fileName, fileURL: imageUrl });
                setCurrentMedia(updatedMedia);
                setSelectedVideo(null); 
                setSelectedSlideshow(updatedSlideshow);
                
                // Reflect changes in the template object
                // templateObj?.media?.slideshow = updatedSlideshow;
    console.log(updatedMedia,"updatedMedia123");
    
                // Update template media
                updateTemplateMedia(updatedMedia);
            },
            getBase64,
        });
    }
    const getBase64 = async (files) => {
        if (files.length === 1) {
            return new Promise((resolve) => {
                let reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = async (e) => {
                    const ratio = await getRatio(e.target.result);
                    resolve([{ b64: reader.result, ratio }]);
                };
            });
        } else {
            const fileData = Object.values(files);
            const filePathsPromises = [];
            fileData.forEach((file) => {
                filePathsPromises.push(
                    new Promise((resolve) => {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async (e) => {
                            const ratio = await getRatio(e.target.result);
                            resolve({ b64: reader.result, ratio });
                        };
                    }),
                );
            });
            const filePaths = await Promise.all(filePathsPromises);
            return filePaths;
        }
    };

    const getRatio = async (url) => {
        console.log("URL rat", url);
        return new Promise((resolve) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                let ratio = img.width / img.height;
                resolve(ratio);
            };
        });
    };

    // Handle file change for image
    // const handleFileChange = (event) => {
    //     const file = event.target.files[0]; 
    //     if (file && file.type.startsWith("image/")) {
    //         const fileURL = URL.createObjectURL(file); 
    //         console.log(fileURL,"fileURL");
    //         setSelectedFile({ fileName: file.name, fileURL });
    //         const updatedMedia = { ...currentMedia, file: file.name, type: 'image',fileURL:fileURL };
    //         setCurrentMedia({ ...currentMedia, file: file.name, type: 'image' ,fileURL:fileURL});
    //         setSelectedVideo(null); 
    //         setSelectedSlideshow([]); 
    //         updateTemplateMedia(updatedMedia);
    //         event.target.value = null; 
    //     } else {
    //         alert("Please select an image file.");
    //     }
    // };

    
    const handleFileChange = async (event) => {
        const _brandingLogoSupportedImageTypes = ["image/png"];
        const uploadAPIRoute = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-file`;
        const brandingUploadAPIRoute = `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_ENV}/v1/upload-content-badge`;
        const supportedImageTypes = ["image/jpeg", "image/png"];
        const fileName = event.target.files[0]?.name;
        await imageHandlerUtil({
            event,
            supportedImageTypes,
            maxSize: 1048576 * 5, // Max size 5MB
            uploadAPIRoute: uploadAPIRoute,
            handleImageInputError: (id, message) => {
                console.log(`Error for ${id}: ${message}`);
            },
            handleUploadSuccess: (id, imageUrl) => {
                console.log(imageUrl, "imageUrlerer");
                setSelectedFile({ fileName: fileName, fileURL:imageUrl });
                const updatedMedia = { ...currentMedia, file: fileName, type: 'image',fileURL:imageUrl ,slideshow:[]};
                setCurrentMedia({ ...currentMedia, file: fileName, type: 'image' ,fileURL:imageUrl ,slideshow:[]});
                setSelectedVideo(null); 
                setSelectedSlideshow([]); 
                updateTemplateMedia(updatedMedia);
                event.target.value = null; 
            },
            getBase64,
        });
    }
    


    // Handle file change for video
    const handleVideoChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type.startsWith("video/")) {
            const fileURL = URL.createObjectURL(file); 
            setSelectedVideo({ fileName: file.name, fileURL });
            const updatedMedia = { ...currentMedia, file: file.name, type: 'video',fileURL:fileURL };

            setCurrentMedia({ ...currentMedia, file: file.name, type: 'video' ,fileURL:fileURL});
            setSelectedFile(null);
            setSelectedSlideshow([]); 
            updateTemplateMedia(updatedMedia);
            event.target.value = null; 
        } else {
            alert("Please select a video file.");
        }
    };
    // const handleSlideshowChange = (event) => {
    //     const files = Array.from(event.target.files);
    //     if (files.length > 0) {
    //         const fileURLs = files.map((file) => URL.createObjectURL(file));
            
    //         // Combine existing and new files for the slideshow
    //         const updatedSlideshow = [
    //             ...currentMedia.slideshow,
    //             ...files.map((file) => file.name)
    //         ];
    
    //         const updatedSlideshowUrls = [
    //             ...selectedSlideshow,
    //             ...files.map((file, index) => ({
    //                 fileName: file.name,
    //                 fileURL: fileURLs[index]
    //             }))
    //         ];
    
    //         const updatedMedia = {
    //             ...currentMedia,
    //             type: 'slideshow',
    //             slideshow: updatedSlideshow
    //         };
    
    //         setSelectedSlideshow(updatedSlideshowUrls);
    //         setCurrentMedia(updatedMedia);
    //         setSelectedFile(null); // Clear selected file
    //         setSelectedVideo(null); // Clear selected video
    //         updateTemplateMedia(updatedMedia);
    
    //         event.target.value = null; // Reset input
    //     }
    // };
    

    // Handle replacing the current photo
    const handleReplacePhoto = () => {
        document.getElementById('file-input').click();
    };

    // Handle replacing the current video
    const handleReplaceVideo = () => {
        document.getElementById('video-input').click();
    };

    // Handle replacing the current slideshow
    const handleReplaceSlideshow = () => {
        setTemplateObj(prevState => ({
            ...prevState,
            media: {
                ...prevState.media,
                slideshow: [], 
            }
        }));
        document.getElementById('slideshow-input').click();
    };

    // Handle clearing the media
    const handleClearMedia = () => {
        const clearedMedia = { file: '', type: '', slideshow: [] };

        setCurrentMedia(clearedMedia);
        setSelectedFile(null);
        setSelectedVideo(null);
        setSelectedSlideshow([]);
        updateTemplateMedia(clearedMedia);
    };

    // Handle changing the position menu
    const handlePositionMenuOpen = (event) => {
        setPositionAnchorEl(event.currentTarget);
        setIsPositionSubMenuOpen(true);
    };

    const handlePositionMenuClose = () => {
        setIsPositionSubMenuOpen(false);
    };

    // Handle position change
    const handlePositionChange = (position) => {
        setCurrentMedia((prevMedia) => {
            // Ensure `fileURL` is retained by updating only `position`
            const updatedMedia = { ...prevMedia, position: position };
            updateTemplateMedia(updatedMedia); // Assuming this needs the updated media
            return updatedMedia;
        });
        handlePositionMenuClose();
    };

    // Check if any media has been added (photo, video, or slideshow)
    const isMediaAdded = currentMedia.type !== '';
    

    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleMainMenuOpen}
                startIcon={<img src={MediaIcon} alt="form Icon" width={20} height={20} />} 

                endIcon={<ArrowDropDownIcon />}
            >
                Media
            </Button>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMainMenuClose}
                PaperProps={{ style: { width: '300px' } }}
            >
                {/* Add Media Options with Plus Icon */}
                <MenuItem
                    onClick={() => document.getElementById('file-input').click()}
                >
                    <AddCircleIcon style={{ marginRight: '10px' }} />
                    Add Photo
                </MenuItem>
                <MenuItem
                    onClick={() => document.getElementById('video-input').click()}
                    disabled
                >
                    <AddCircleIcon style={{ marginRight: '10px' }} />
                    Add Video
                </MenuItem>
                <MenuItem
                    onClick={() => document.getElementById('slideshow-input').click()}
                >
                    <AddCircleIcon style={{ marginRight: '10px' }} />
                    Add Slideshow
                </MenuItem>

                <Divider />

                {/* Current Media Section */}
                <MenuItem disabled>
                    <ListItemText
                        primary="Current Media"
                        style={{ textAlign: 'center', fontWeight: 'bold' }}
                    />
                </MenuItem>

                {/* Display File Section */}
                {currentMedia.type === 'image' && selectedFile ? (
                    <div>
                        <MenuItem onClick={() => setIsSubMenuOpen((prev) => !prev)}>
                            <ImageIcon style={{ marginRight: '10px' }} /> {/* Image icon */}
                            <ListItemText primary={`File: ${currentMedia.file}`} />
                            <ArrowDropDownIcon />
                        </MenuItem>

                        {/* Submenu for managing the uploaded file */}
                        {isSubMenuOpen && (
                            <Menu
                                open={isSubMenuOpen}
                                onClose={() => setIsSubMenuOpen(false)}
                                anchorEl={anchorEl}
                            >
                                <MenuItem disabled>
                                    <ImageIcon style={{ marginRight: '10px' }} /> {/* Image icon */}
                                    <strong>{currentMedia.file}</strong>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleClearMedia} style={{ color: 'red' }}>
                                    <ClearIcon style={{ marginRight: '10px' }} /> Delete Photo
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleReplacePhoto}>
                                    <PhotoLibraryIcon style={{ marginRight: '10px' }} /> Replace Photo
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                ) : currentMedia.type === 'video' && selectedVideo ? (
                    <div>
                        <MenuItem onClick={() => setIsSubMenuOpen((prev) => !prev)}>
                            <VideoCameraIcon style={{ marginRight: '10px' }} /> {/* Video icon */}
                            <ListItemText primary={`Video: ${currentMedia.file}`} />
                            <ArrowDropDownIcon />
                        </MenuItem>

                        {/* Submenu for managing the video */}
                        {isSubMenuOpen && (
                            <Menu
                                open={isSubMenuOpen}
                                onClose={() => setIsSubMenuOpen(false)}
                                anchorEl={anchorEl}
                            >
                                <MenuItem disabled>
                                    <strong>{currentMedia.file}</strong>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleClearMedia} style={{ color: 'red' }}>
                                    <ClearIcon style={{ marginRight: '10px' }} /> Delete Video
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleReplaceVideo}>
                                    <PhotoLibraryIcon style={{ marginRight: '10px' }} /> Replace Video
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                ) : currentMedia.type === 'slideshow' && selectedSlideshow.length > 0 ? (
                    <div>
                        <MenuItem onClick={() => setIsSubMenuOpen((prev) => !prev)}>
                            <PhotoLibraryIcon style={{ marginRight: '10px' }} /> {/* Slideshow icon */}
                            <ListItemText primary={`Slideshow`} />
                            <ArrowDropDownIcon />
                        </MenuItem>

                        {/* Submenu for managing the slideshow */}
                        {isSubMenuOpen && (
                            <Menu
                                open={isSubMenuOpen}
                                onClose={() => setIsSubMenuOpen(false)}
                                anchorEl={anchorEl}
                            >
                                <MenuItem disabled>
                                    <strong>{selectedSlideshow.length} items</strong>
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleClearMedia} style={{ color: 'red' }}>
                                    <ClearIcon style={{ marginRight: '10px' }} /> Delete Slideshow
                                </MenuItem>
                                <Divider />
                                <MenuItem onClick={handleReplaceSlideshow}>
                                    <PhotoLibraryIcon style={{ marginRight: '10px' }} /> Replace Slideshow
                                </MenuItem>
                            </Menu>
                        )}
                    </div>
                ) : null}

                <Divider />

                {/* Position Selection */}
                <MenuItem
                    onClick={handlePositionMenuOpen}
                    disabled={!isMediaAdded} // Disable if no media is added
                >
                    Change Position <ChevronRightIcon style={{ marginLeft: 'auto' }} />
                </MenuItem>

                {isPositionSubMenuOpen && (
                    <Menu
                        anchorEl={positionAnchorEl}
                        open={isPositionSubMenuOpen}
                        onClose={handlePositionMenuClose}
                    >
                        {!templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem disabled={templateObj?.form?.position === "Left"} onClick={() => handlePositionChange('Left')}>Left</MenuItem>}
                        {!templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem disabled={templateObj?.form?.position === "Right"} onClick={() => handlePositionChange('Right')}>Right</MenuItem>}
                        {!templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem disabled={templateObj?.form?.position === "Center"} onClick={() => handlePositionChange('Center')}>Center</MenuItem>}
                        {/* mobile */}
                        {templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem disabled={templateObj?.form?.position === "Top"} onClick={() => handlePositionChange('Top')}>Top</MenuItem>}
                        {templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem disabled={templateObj?.form?.position === "Bottom"} onClick={() => handlePositionChange('Bottom')}>Bottom</MenuItem>}
                        {templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem   onClick={() => setTemplateObj({...templateObj,media:{...templateObj?.media,position:""}})} style={{color:"red"}}>Clear Position</MenuItem>}
                    </Menu>
                )}
            </Menu>

            {/* Hidden Inputs for File Selection */}
            <Input
                id="file-input"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />
            <Input
                id="video-input"
                type="file"
                accept="video/*"
                onChange={handleVideoChange}
                style={{ display: 'none' }}
            />
            <Input
                id="slideshow-input"
                type="file"
                accept="image/*"
                multiple
                onChange={handleSlideshowChange}
                style={{ display: 'none' }}
            />
        </div>
    );
};

export default MediaDropdown;
