import React from 'react';
import Slider from 'react-slick';
// import './Carousel.css';
// import { images } from '../../constants/index'; 

const Carousel = ({templateObj}) => {
    const settings = {
        dots: false, 
        infinite: true, 
        speed: 500, 
        slidesToShow: 1, 
        slidesToScroll: 1, 
        autoplay: true, 
        autoplaySpeed: 3000, 
        pauseOnHover: true, 
        arrows: false, 
    };
    console.log(templateObj.media.slideshow,"caratemplateObj");
    
    const slickStyleOverrides = {
        '.carousel-container': {
            height: "100vh",
            width: "22vw",
            display: "flex",
            alignContent: "center",
            alignContent: "center",
            overflow: "hidden",
        },
        '.carousel-container:hover': {
            cursor: 'pointer',
        },
        '.slick-slider, .slick-list, .slick-track': {
            width: '100%',
            height: '100%',
        },
        '.slide': {
            height: '100vh',
            display: 'flex',
            alignContent: 'center',
        },
        '.slide img': {
            width: '30vw',
            padding: '20px',
        },
        // '.content-container': {
        //     padding: '10px',
        // },
        // '.content-container h5': {
        //     color: 'white',
        //     fontWeight: 'bold',
        //     margin: '10px 0 5px',
        //     textAlign: 'center',
        //     fontSize: '1.2rem',
        // },
        // '.content-container p': {
        //     color: 'white',
        //     margin: '0',
        //     textAlign: 'left',
        //     fontSize: '1rem',
        // },
    };
    return (        
            <div className="carousel-container">
            <style>
                {`
                ${Object.entries(slickStyleOverrides)
                    .map(([key, value]) =>
                        `${key} { ${Object.entries(value)
                            .map(([prop, val]) => `${prop}: ${val};`)
                            .join(' ')} }`
                    )
                    .join(' ')}
                `}
            </style>
            <Slider {...settings}>
                {templateObj?.media?.slideshow?.map((image,i) => (
                    <div className="slide" key={i}>
                        {/* <div 
                            style={{
                             position:'absolute',
                             width: '20vw',
                             height: '20vw',
                             background: 'radial-gradient(50% 50% at 50% 50%, #8F90D2 0%, rgba(143, 144, 210, 0) 100%)',
                             transform:'translateX(6vw)',
                             zIndex:'-1',
                         }}
                        > 
                        </div> */}
                        <img src={image?.fileURL} alt={image?.fileName} style={{width:"20vw"}}/>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Carousel;

