import React from 'react';
import { Box, Typography, TextField, Button, InputAdornment } from '@mui/material';
import GoogleElement from './GoogleElement';
import GoogleIcon from "../../../assets/img/SignInTemplateAssets/google.svg"
import FacebookIcon from "../../../assets/img/SignInTemplateAssets/facebook.svg"
import QRCODE from "../../../assets/img/SignInTemplateAssets/HorizonQR.png"



const SignInForm = ({
    buttonTextColor,
    onSignUpClick,
    backgroundColor = '#FFFFFF', // Default background color
    horizontal = false,
    templateObj
}) => {
    console.log(templateObj, "templateObjSignInForm");
    const fontSize = templateObj?.form?.fontSize || "";
    const fontColor = templateObj?.form?.fontColor || "";
    const buttonColor = templateObj?.form?.buttonColor || "";
    const fontFamily = templateObj?.form?.currentFont || ""; 
    const fieldColor = templateObj?.form?.fieldColor || "";
    console.log(fieldColor,"fieldColor");
    
    return (
        <Box
            sx={{
                width: 300,
                p: 4,
                borderRadius: 2,
                boxShadow: 3,
                bgcolor: backgroundColor, // Dynamic background color
                textAlign: 'center',
            }}
        >
           {templateObj?.form?.type==="QR Code"?(
             <>
                {/* QR Code Section */}
                <Typography
                    variant="h6"
                    sx={{
                        color: fontColor,
                        mb: 2,
                        fontFamily: fontFamily || "sans-serif",
                        // textDecoration: 'underline',
                        textTransform: 'none',
                        fontSize:fontSize
                    }}
                >
                    Scan with Phone Camera to {templateObj?.templateName === "Login Screen" ? "LogIn" :"SignUp"}
                </Typography>
                <Box
                    component="img"
                    src={QRCODE}
                    alt="QR Code"
                    sx={{
                        width: 250,
                        height: 250,
                        mx: 'auto',
                        borderRadius: 2,
                    }}
                />
                <Typography
                    variant="body2"
                    sx={{ mt: 2, fontSize:fontSize, color: fontColor, fontFamily: fontFamily || "sans-serif" }}
                >
                   {templateObj?.templateName === "Login Screen" ? "Don’t have an account?" : "Already have an Account?"}{' '}
                    <Box component="span" sx={{ fontWeight: 'bold', cursor: 'pointer', color:buttonColor }}>
                        {templateObj?.templateName !== "Login Screen" ? "Log In" :"Sign Up"}
                    </Box>
                </Typography>
            </>):(
                <>
             <Typography variant="h5" sx={{ fontSize, color: fontColor, mb: 2, fontFamily: fontFamily || "sans-serif" }}>
             {templateObj?.templateName == "Login Screen" ? "Log In" :"Sign Up"}
            </Typography>
            {templateObj?.form?.type === "Email" && <TextField
                fullWidth
                label="Email"
                margin="normal"
                variant="outlined"
                InputProps={{
                    style: { fontSize, color: fontColor, fontFamily: fontFamily || "sans-serif", background: fieldColor },
                }}
            />}
            {templateObj?.form?.type === "OTP" && <TextField
                fullWidth
                label="Phone Number"
                margin="normal"
                variant="outlined"
                InputProps={{
                    style: { fontSize, color: fontColor, fontFamily: fontFamily || "sans-serif", background: fieldColor },
                    endAdornment: (
                        <InputAdornment position="end">
                            <Button
                                variant="contained"
                                size="small"
                                sx={{
                                    bgcolor: buttonColor || 'blue',
                                    color: buttonTextColor || 'white',
                                    ':hover': {
                                        bgcolor: `${buttonColor || 'blue'}D9`,
                                    },
                                    fontSize: fontSize || '0.875rem',
                                    fontFamily: fontFamily || "sans-serif",
                                    padding: '4px 8px',
                                    minWidth: 'fit-content',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    lineHeight: 1.2, // Adjust line spacing
                                }}
                                onClick={""}
                            >
                                <Typography variant="body2" component="span">Send</Typography>
                                <Typography variant="body2" component="span">OTP</Typography>
                            </Button>
                        </InputAdornment>
                    ),
                }}
            />}
            {templateObj?.form?.type === "Email" && <TextField
                fullWidth
                label="Password"
                type="password"
                margin="normal"
                variant="outlined"
                InputProps={{
                    style: { fontSize, color: fontColor, fontFamily: fontFamily || "sans-serif", background: fieldColor },
                }}
            />}
            {templateObj?.form?.type === "OTP" && <TextField
                fullWidth
                label="Enter OTP"
                margin="normal"
                variant="outlined"
                type="text"
                InputProps={{
                    style: { fontSize, color: fontColor, fontFamily: fontFamily || "sans-serif", background: fieldColor },
                }}
            />}
            <Button
                variant="contained"
                fullWidth
                sx={{
                    mt: 2,
                    bgcolor: buttonColor,
                    color: buttonTextColor,
                    ':hover': {
                        bgcolor: `${buttonColor}D9`, 
                    },
                }}
            >
            {templateObj?.templateName === "Login Screen" ? "Log In" :"Sign Up"}

            </Button>
            <Typography sx={{ mt: 2, color: fontColor, fontSize, fontFamily: fontFamily || "sans-serif" }}>
            {templateObj?.templateName === "Login Screen" ? "Don’t have an account?" : "Already have an Account?"}{' '}
                <span
                    style={{ color: buttonColor, cursor: 'pointer' }}
                    onClick={onSignUpClick}
                >
                {templateObj?.templateName !== "Login Screen" ? "Log In" :"Sign Up"}

                </span>
            </Typography>
            </>
        )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: templateObj?.socialMedia?.settings?.order !== "Vertical" ? 'row' : 'column',
                    gap: 2,
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px"
                }}
            >
                {/* Google Sign-In Button 1 */}
                {templateObj?.socialMedia?.settings?.position !== "Below Form" && templateObj?.socialMedia?.checkboxes?.google && <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px 20px',
                        borderRadius: '20px',
                        color: templateObj?.socialMedia?.settings?.fontColor || "black",
                        // fontFamily: templateObj?.socialMedia?.settings?.fontFamily,
                        background: templateObj?.socialMedia?.settings?.buttonColor || "white",
                        fontSize: '16px',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease',
                        justifyContent: 'center',
                        whiteSpace: 'nowrap',

                    }}
                >
                    <img
                        src={GoogleIcon}
                        alt="Google Icon"
                        width={24}
                        height={24}
                    />
                    {templateObj?.socialMedia?.settings?.type === "Logo Name" && <Typography
                        variant="body1"
                        sx={{
                            fontWeight: 'bold', marginLeft: '10px', whiteSpace: 'nowrap', fontFamily: templateObj?.socialMedia?.settings?.fontFamily || "sans-serif"
                        }} // Prevents text wrapping
                    >
                        {templateObj?.socialMedia?.settings?.order === "Vertical" ? "Sign in with google" : "Sign in"}
                    </Typography>}
                </Box>}

                {/*  Button 2 */}
                {templateObj?.socialMedia?.settings?.position !== "Below Form" && templateObj?.socialMedia?.checkboxes?.facebook &&
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px 20px',
                            borderRadius: '20px',
                            fontSize: '16px',
                            cursor: 'pointer',
                            color: templateObj?.socialMedia?.settings?.fontColor || "black",
                            // fontFamily: templateObj?.socialMedia?.settings?.fontFamily,
                            background: templateObj?.socialMedia?.settings?.buttonColor || "white",
                            transition: 'background-color 0.3s ease',
                            justifyContent: 'center',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <img
                            src={FacebookIcon}
                            alt="Facebook Icon"
                            width={24}
                            height={24}
                        />
                        {templateObj?.socialMedia?.settings?.type === "Logo Name" && <Typography
                            variant="body1"
                            sx={{
                                fontWeight: 'bold',
                                marginLeft: '10px',
                                whiteSpace: 'nowrap',
                                fontFamily: templateObj?.socialMedia?.settings?.fontFamily || "sans-serif"
                            }}
                        >
                            {templateObj?.socialMedia?.settings?.order === "Vertical" ? "Sign in with Facebook" : "Sign in"}
                        </Typography>}
                    </Box>}
            </Box>

        </Box>
    )
};

export default SignInForm;
