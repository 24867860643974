import React, { useEffect, useState } from 'react';
import { Button, Menu, MenuItem, Popover, Typography, IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import FormIcon from "../../assets/img/SignInTemplateAssets/FormIcon.svg"

const Dropdown = ({ setTemplateObj, templateObj, activeTab }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null); // Track which submenu is open
  const [selectedValue, setSelectedValue] = useState({
    type: '',
    position: '',
    currentFont: '',
    fontSize: '',
    fontColor: '',
    fieldColor: '',
    buttonColor: '',
  });
  const [importedFont, setImportedFont] = useState('');
  const [availableFonts, setAvailableFonts] = useState(['Arial', 'Helvetica', 'Times New Roman', 'Georgia', 'Courier New']);

  useEffect(() => {
    if (templateObj?.media) {
      setSelectedValue(templateObj.form);

    }
  }, [templateObj])

  const handleMainMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setSelectedSubMenu(null);
  };

  const handleMainMenuClose = () => {
    setAnchorEl(null);
    setSelectedSubMenu(null);
  };

  const handleSubmenuOpen = (menu) => {
    setSelectedSubMenu(menu);
  };
  const updateTemplateObj = (newValues) => {
    setTemplateObj(prev => ({
      ...prev,
      form: {
        ...prev.form,
        ...newValues,
      }
    }));
  };

  const handleSelection = (category, value) => {
    setSelectedValue((prev) => ({ ...prev, [category]: value }));
    updateTemplateObj({ [category]: value });
    handleMainMenuClose();
  };

  const handleFontImport = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const fontName = file.name.split('.').slice(0, -1).join('.');
        const fontFace = new FontFace(fontName, reader.result);
        fontFace.load().then(() => {
          document.fonts.add(fontFace);
          setImportedFont(fontName);
          handleSelection('currentFont', fontName);
          setAvailableFonts((prev) => [...prev, fontName]);
        });
      };
      reader.readAsArrayBuffer(file);
    }
  };
  const handleFontSizeChange = (event) => {
    const newFontSize = parseInt(event.target.value, 10);
    if (!isNaN(newFontSize) && newFontSize >= 8 && newFontSize <= 72) {
      setSelectedValue((prev) => ({
        ...prev,
        fontSize: newFontSize,
      }));
      updateTemplateObj({ fontSize: newFontSize });
    }
  };

  // Handle font color change
  const handleColorChange = (category, color) => {
    setSelectedValue((prev) => ({ ...prev, [category]: color }));
    updateTemplateObj({ [category]: color });
  };

  const handleClearColor = (category) => {
    setSelectedValue((prev) => ({ ...prev, [category]: '' }));
    updateTemplateObj({ [category]: '' });
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleMainMenuOpen}
        startIcon={<img src={FormIcon} alt="form Icon" width={20} height={20} />}
        endIcon={<ArrowDropDownIcon />}
      >
        Form
      </Button>

      {/* Main Menu */}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMainMenuClose}
      >
        <MenuItem
          onClick={() => handleSubmenuOpen('type')}
        // style={{ color: selectedValue.type ? 'lightblue' : 'inherit' }}
        >
          Type: {selectedValue.type || ''} <ArrowDropDownIcon />
        </MenuItem>
        <MenuItem
          onClick={() => handleSubmenuOpen('position')}
        // style={{ color: selectedValue.position ? 'lightblue' : 'inherit' }}
        >
          Position: {selectedValue.position || ''} <ArrowDropDownIcon />
        </MenuItem>
        <MenuItem>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Font Size:</span>
            <input
              type="number"
              min="8"
              max="72"
              value={selectedValue.fontSize}
              onChange={handleFontSizeChange}
              style={{
                marginLeft: '10px',
                width: '60px',
                padding: '4px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                textAlign: 'center',
              }}
            />
            <span style={{ marginLeft: '5px' }}>px</span>
          </div>
        </MenuItem>
        <MenuItem>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Font Color:</span>
            <input
              type="color"
              value={selectedValue.fontColor || '#000000'}
              onChange={(e) => handleColorChange('fontColor', e.target.value)}
              style={{
                marginLeft: '10px',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                overflow: 'hidden',
                padding: 0,
                border: 'none',
                cursor: 'pointer',
              }}
            />
            {selectedValue.fontColor && (
              <IconButton onClick={() => handleClearColor('fontColor')} style={{ padding: '5px' }}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </MenuItem>

        {/* Field Color with Clear Option */}
        <MenuItem>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Field Color:</span>
            <input
              type="color"
              value={selectedValue.fieldColor || '#fffff'}
              onChange={(e) => handleColorChange('fieldColor', e.target.value)}
              style={{
                marginLeft: '10px',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                overflow: 'hidden',
                padding: 0,
                border: 'none',
                cursor: 'pointer',
              }}
            />
            {selectedValue.fieldColor && (
              <IconButton onClick={() => handleClearColor('fieldColor')} style={{ padding: '5px' }}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </MenuItem>

        {/* Button Color with Clear Option */}
        <MenuItem>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>Button Color:</span>
            <input
              type="color"
              value={selectedValue.buttonColor || '#0000ff'}
              onChange={(e) => handleColorChange('buttonColor', e.target.value)}
              style={{
                marginLeft: '10px',
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                overflow: 'hidden',
                padding: 0,
                border: 'none',
                cursor: 'pointer',
              }}
            />
            {selectedValue.buttonColor && (
              <IconButton onClick={() => handleClearColor('buttonColor')} style={{ padding: '5px' }}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => handleSubmenuOpen('currentFont')}
        // style={{ color: selectedValue.currentFont ? 'lightblue' : 'inherit' }}
        >
          Current Font: {selectedValue.currentFont || ''} <ArrowDropDownIcon />
        </MenuItem>
      </Menu>

      {/* Submenu for Type */}
      {selectedSubMenu === 'type' && (
        <Popover
          open={Boolean(selectedSubMenu === 'type')}
          onClose={() => setSelectedSubMenu(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div style={{ padding: '10px' }}>
            <Typography variant="body1">Select Type:</Typography>
            <MenuItem onClick={() => handleSelection('type', 'Email')}>Email</MenuItem>
            <MenuItem onClick={() => handleSelection('type', 'OTP')}>OTP</MenuItem>
            <MenuItem onClick={() => handleSelection('type', 'QR Code')}>QR Code</MenuItem>
          </div>
        </Popover>
      )}

      {/* Submenu for Position */}
      {selectedSubMenu === 'position' && (
        <Popover
          open={Boolean(selectedSubMenu === 'position')}
          anchorEl={anchorEl}
          onClose={() => setSelectedSubMenu(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div style={{ padding: '10px' }}>
            <Typography variant="body1">Select Position:</Typography>
            {!templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem disabled={templateObj?.media?.position === "Left"} onClick={() => handleSelection('position', 'Left')}>Left</MenuItem>}
            {!templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem disabled={templateObj?.media?.position === "Center"} onClick={() => handleSelection('position', 'Center')}>Center</MenuItem>}
            {!templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem disabled={templateObj?.media?.position === "Right"} onClick={() => handleSelection('position', 'Right')}>Right</MenuItem>}
            {/* mobile */}
            {templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem disabled={templateObj?.media?.position === "Top"} onClick={() => handleSelection('position', 'Top')}>Top</MenuItem>}
            {templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem disabled={templateObj?.media?.position === "Bottom"} onClick={() => handleSelection('position', 'Bottom')}>Bottom</MenuItem>}
            {templateObj.platform?.some(platform => ["WEB_MOB", "AND_MOB", "IOS_MOB"].includes(platform)) && <MenuItem style={{color:"red"}}  onClick={() => setTemplateObj({...templateObj,form:{...templateObj?.form,position:""}})}>Clear Positions</MenuItem>}

          </div>
        </Popover>
      )}

      {/* Submenu for Current Font */}
      {selectedSubMenu === 'currentFont' && (
        <Popover
          open={Boolean(selectedSubMenu === 'currentFont')}
          onClose={() => setSelectedSubMenu(null)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <div style={{ padding: '10px' }}>
            <Typography variant="body1">Import Font:</Typography>
            <input
              type="file"
              accept=".ttf, .otf, .woff, .woff2"
              onChange={handleFontImport}
              style={{ marginBottom: '10px' }}
            />
            <Typography variant="body1">Available Fonts:</Typography>
            {availableFonts.map((font) => (
              <MenuItem key={font} onClick={() => handleSelection('currentFont', font)}>
                {font}
              </MenuItem>
            ))}
          </div>
        </Popover>
      )}

      {/* Display Selected Values */}
      {/* <div style={{ marginTop: '20px' }}>
        <h4>Selected Values:</h4>
        <p>Type: {selectedValue.type}</p>
        <p>Position: {selectedValue.position}</p>
        <p>Current Font: {selectedValue.currentFont}</p>
        <p>Font Size: {selectedValue.fontSize}</p>
        <p>Font Color: {selectedValue.fontColor}</p>
        <p>Field Color: {selectedValue.fieldColor}</p>
        <p>Button Color: {selectedValue.buttonColor}</p>
      </div> */}
    </div>
  );
};

export default Dropdown;
